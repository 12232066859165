import React from 'react';
import { Popover } from 'antd';

const HoverFloatingWindow = () => {
  return (
    <div>
      <Popover
        content={
          <div>
            <p>有疑问联系客服，请用微信扫一扫</p>
            <img
              src="https://tiktok-ad-tool-1255795714.cos.ap-shanghai.myqcloud.com/home_page/企业微信二维码.jpeg"
              alt="企业微信二维码"
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        }
        trigger="hover"
        placement="top"
        overlayStyle={{
          width: '140px',  // 设置悬浮窗的宽度
        }}
      >
        <img
          src="https://tiktok-ad-tool-1255795714.cos.ap-shanghai.myqcloud.com/home_page/客服图标.png"
          alt="联系客服"
          style={{ maxWidth: '70%', height: 'auto' }}
        />
      </Popover>
    </div>
  );
};

export default HoverFloatingWindow;