/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-12-05 17:52:11
 * @LastEditors: changfeng
 * @LastEditTime: 2023-07-18 11:41:05
 */
export default {
  'strategy.name': '策略名称',
  'strategy.status': '策略状态',
  'strategy.type': '策略类型',

  'strategy.too.much': '您最多可创建20条策略',

  'strategy.name.placeholder': '请输入策略名称',
  'strategy.effective_time': '生效时间',
  'strategy.effective_time.tooltip': '策略只会在设置的时间内生效',

  'strategy.effective_account': '请选择生效的广告账户',
  'strategy.latitude.title': '生效维度',
  'strategy.latitude.advertiser': '广告账户',
  'strategy.latitude.campaign': '推广系列',
  'strategy.latitude.adgroup': '广告组',
  'strategy.latitude.ad': '广告',
  'strategy.latitude.errorMessage': '必须选择生效纬度',
  'strategy.latitude.scopeId': '维度ID',

  'strategy.scope.title': '生效范围',
  'strategy.scope.STATUS_DELIVERY_OK': '投放中',
  'strategy.scope.STATUS_DISABLE': '已暂停',
  'strategy.scope.STATUS_NOT_DELIVERY': '未投放',
  'strategy.scope.STATUS_NOT_DELETE': '所有',
  'strategy.scope.errorMessage': '必须选择生效范围',

  'strategy.repeat.title': '重复生效',
  'strategy.repeat.always': '重复',
  'strategy.repeat.once': '每天一次',
  'strategy.repeat.errorMessage': '请选择是否重复',

  'strategy.reportPeriod.title': '报表时间周期',
  'strategy.reportPeriod.today': '当天',
  'strategy.reportPeriod.last two days': '最近两天',
  'strategy.reportPeriod.last three days': '最近三天',
  'strategy.reportPeriod.last seven days': '最近七天',
  'strategy.reportPeriod.last two weeks': '最近两周',
  'strategy.reportPeriod.Advertising period': '广告发布期间',
  'strategy.reportPeriod.errorMessage': '请选择报表生效维度',


  'strategy.rule_condition': '策略条件',
  'strategy.action': '策略动作',
  'strategy.action.params': '动作参数',

  'strategy.frequency.title': '执行频率',
  'strategy.frequency.placeholder': '请选择执行频率',
  'strategy.frequency.mins': '分钟',
  'strategy.frequency.hour': '小时',
  'strategy.frequency.hours': '小时',

  'strategy.log.startTime': '开始时间',
  'strategy.log.endTime': '结束时间',
};
