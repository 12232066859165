/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-02-10 15:06:16
 * @LastEditors: changfeng
 * @LastEditTime: 2023-02-16 16:56:33
 */
export default {
  'home.nav.home': 'Home',
  'home.nav.product': ' Service',
  'home.nav.project': 'Case',
  'home.nav.login': 'Login',
  'home.nav.register': 'Sign up (for free)',
  'home.nav.privacy': 'Privacy',
  'home.sectionOne.title': 'Help your monitor TikTok advertising',
  'home.sectionOne.freeTry': 'Free Trial',
  'home.section2.title': 'Encounter some problems',
  'home.section2.title1': 'More time Better creativity',
  'home.section2.title2': '扩量效率低下,',
  'home.section2.world':
    'Free the eyes of the advertisers, focus the time of staring on the creation of advertising creatives, and spend more time creating more high-quality advertising creatives, so that the customer experience will be better.',
  'home.section3.title': 'Product introduce',
  'home.section3.title1': 'Manage your accounts smartly',
  'home.section3.list1':
    'Connect to TikTok‘s API and track the performance every milliseconds',
  'home.section3.list2':
    'Build custom rules and develop diversified strategies',
  'home.section3.list3':
    'Automatically turn off ads  to reduce advertising costs',
  'home.section3.list4':
    'Automatically monitor past data to improve campaign fault tolerance',
  'home.section4.img.title1': '美妆',
  'home.section4.img.subtitle1': '0201-测试1',
  'home.section4.title1': 'Deliver ads in bulk',
  'home.section4.list1': 'Achieving 1000+ campaigns for high volume ',
  'home.section4.list2':
    'Deliver ads in bulk and duplicate ads fast in the same scene',
  'home.section4.list3':
    'Differentiated advertising to ensure no volume grabbing between campaigns',
  'home.section5.title': 'Multi-dimensional data analysis',
  'home.section5.list1':
    'Multi-dimensional visual data analysis including creatives, campaigns,targeting,copywriting and etc.',
  'home.section5.list2': 'One-click export of multi-account data reports',
  'home.section5.list3': 'Real-time tracking of delivery performance',
  'home.section5.list4': 'Data reporting time granularity to the minute',
  'home.section6.title': 'Rules marketplace',
  'home.section6.list1':
    'Numbers of classic rules that have been verified by millions of cost are shared',
  'home.section6.list2':
    'A variety of delivery rules and massive data analysis',
  'home.section6.list3': 'Novice use advanced rules with one click',
  'home.section7.title': 'Case Analysis',
  'home.section7.useTool': 'After using the NewRate Tool, ',
  'home.section7.unitl': 'times',
  'home.section7.person1': 'Custom Gifts Ecommerce Marketer',
  'home.section7.person1.problem1':
    '1.Problem1：They have large numbers of accounts, campaigns and creatives, and multiple landing pages.',
  'home.section7.person1.problem2':
    'Problem2：The cost is not easy to control, and the campaign is left unattended at night.',
  'home.section7.person1.resolve1':
    '1.Solution: Multi-dimensional allocation of batch landing page conversion from accounts, series, etc., greatly improving customer efficiency.',
  'home.section7.person1.resolve2':
    '2.Solution: Free the eyes of the advertisers through intelligent monitoring accounts, setting conditions to automatically turn off ads and effectively controlling testing costs.',
  'home.section7.person1.promote1.item': 'advertiser efficiency increased by',
  'home.section7.person1.promote1.value': '187',
  'home.section7.person1.promote2.item': 'and spending  increased by',
  'home.section7.person1.promote2.value': '42',
  'home.section7.person2': 'Beauty Shop Team',
  'home.section7.person2.problem1':
    'Problem1：The team is inexperienced and the data is unstable.',
  'home.section7.person2.problem2':
    'Problem2:There are many invalid campaigns, and the shutdown is not timely, resulting in low ROAS.',
  'home.section7.person2.resolve1':
    'Solution：One-click use of a rules marketplace containing ad rules for all categories, avoiding detours for startup teams.',
  'home.section7.person2.resolve2':
    'Solution:Monitoring accounts smartly, such as editing bids automatically and adjusting campaigns in bulk.',
  'home.section7.person2.promote1.item': 'advertiser efficiency increased by',
  'home.section7.person2.promote1.value': '300',
  'home.section7.person2.promote2.item': 'and ROAS increased by',
  'home.section7.person2.promote2.value': '2.5',
  'home.section7.person3': 'Tool App Marketing Manager',
  'home.section7.person3.problem1':
    'Problem1:Difficult to manage with many ad accounts.',
  'home.section7.person3.problem2':
    'Problem2:Counting multi-account and multi-dimensional data is cumbersome.',
  'home.section7.person3.resolve1':
    'Solution:Batch build and adjust ad accounts, support cross-account one-click switching.',
  'home.section7.person3.resolve2':
    'Solution:Multi-dimensional visual data analysis including creatives, campaigns,targeting,copywriting etc. improve data analysis capabilities greatly.',
  'home.section7.person3.promote1.item': 'deliver efficiency increased by',
  'home.section7.person3.promote1.value': '8',
  'home.section7.person3.promote2.item': 'creative testing volume increased by',
  'home.section7.person3.promote2.value': '40',
  'home.section8.title': 'Company introduction',
  'home.section8.text':
    'NewRate is a technology-driven and technological innovation company and a Tier 1 partner of the platform, providing a full range of services for cross-border enterprises.',
  'home.section8.form.title': 'Application experience',
  'home.section8.form.label.name': 'Name',
  'home.section8.form.placeholder.name': 'Please enter your name',

  'home.section8.form.label.email': 'E-mail',
  'home.section8.form.placeholder.email': 'Please enter your E-mail',

  'home.section8.form.label.tel': 'Phone Number',
  'home.section8.form.placeholder.tel': 'Please your phone number',

  'home.section8.form.label.company': 'Business Name',
  'home.section8.form.placeholder.company':
    'Full name of the business license corresponding to the project',

  'home.section8.form.label.business': 'Main Business',
  'home.section8.form.placeholder.business': 'Website or TikTok Shop',

  'home.section8.form.label.category': 'Main Industry',
  'home.section8.form.placeholder.category': 'Beauty makeup/House/Toys',

  'home.section8.form.label.agree': 'Read and agree',
  'home.section8.form.label.privacy':
    'Personal Information and Privacy Protection Treaty',
  'home.section8.form.submit': 'Appointment for trial',
};
