/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-03-20 15:49:58
 * @LastEditors: changfeng
 * @LastEditTime: 2023-07-27 11:11:59
 */
export default {
  'copy.campaign.target': '推广目标',
  'copy.copyTo': '复制到',
  'copy.campaign.exist': '已有的推广系列',
  'copy.campaign.new': '新建推广系列',
  'copy.campaign.choose campaign': '请选择推广系列',
  'copy.campaign.target advertise': "目标广告账户",
  'copy.campaign.target advertise.placeholder': "请选择目标广告账户",
  'copy.campaign.copy count': "复制数量",
  
  'copy.copy': '复制',
  'copy.use all': "  应用到所有",
  'copy.adgroup.title':"复制广告组",
  'copy.campaign.title': "复制推广系列",
  'copy.campaign.message.cbo.info': '源推广系列和目标推广系列预算属性不一致，无法复制',
  'copy.edit.title':"编辑复制"
  
};
