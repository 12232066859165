/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-12-07 10:17:18
 * @LastEditors: changfeng
 * @LastEditTime: 2023-07-26 18:19:44
 */
export default {
  'message.title': 'tip',
  'message.share.tips': 'The share link has been copied to the clipboard',

  'message.delete': 'Are you sure to delete it?',
  'message.delete.strategy': 'Are you sure about the deletion policy?',

  'message.success': 'operate successfully ',
  'message.success.addStrategy': 'Creating a policy succeeded!',
  'message.success.add': 'successfully added',
  'message.success.fixed': 'modify successfully',
  'message.timeRange.info': 'The end time must be longer than the start time',

  /** service error message */
  此用户不存在: 'The user does not exist',
  邀请用户不存在: 'The invited user does not exist',
  用户信息错误: 'User information error',
  '策略不存在,请仔细检查参数':
    'The policy does not exist. Please check the parameters carefully',
  '广告账户不存在，请检查广告账户信息':
    'The advertisement account does not exist. Please check the advertisement account information',
  '告策略已经删除，请勿重复操作':
    'The report policy has been deleted. Do not repeat this operation',
  '邮件格式错误,请检查格式':
    'The message format is incorrect. Please check the format',
  'tiktok 登录地址出现错误,请检查tiktok的登录信息是否正确':
    'The login address for tiktok is incorrect. Please check whether the login information for tiktok is correct',
  策略修改成功: 'The policy is modified successfully.',
  ok: 'ok',
  fail: 'fail',
  loading: 'loading',
  'message.information.atleastone': 'Please select at least one piece of data',
  广告策略已绑定或广告策略为空: 'The AD policy is bound or empty',
  'tiktok信息为进行绑定,请绑定Tiktok信息':
    'tiktok information is not bound. Please bind Tiktok information',

  /** 广告创建的message */
  'message.identity.displayName': 'Enter a display name',
  'message.identity.creating': 'creating',
  'message.adParams.check.landingPage.pre': 'Please add landingPage for the',
  'message.adParams.check.landingPage.last': 'AD',

  'message.adParams.check.identity.pre': 'Please add identity for the',
  'message.adParams.check.identity.last': 'AD',

  'message.adParams.check.adText.pre': 'Please add adText for the',
  'message.adParams.check.adText.last': 'AD',
  'message.back':'back'

};
