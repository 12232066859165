/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-11-30 10:57:30
 * @LastEditors: changfeng
 * @LastEditTime: 2023-03-20 16:04:49
 */
import component from './zh-CN/component';
import menu from './zh-CN/menu';
import pages from './zh-CN/pages';
import pwa from './zh-CN/pwa';
import settingDrawer from './zh-CN/settingDrawer';
import settings from './zh-CN/settings';
import table from './zh-CN/table';
import strategy from './zh-CN/strategy';
import awsome from './zh-CN/awsome';
import user from './zh-CN/user';
import message from './zh-CN/message';
import home from './zh-CN/home';
import privacy from './zh-CN/privacy';
import ad from './zh-CN/ad';
import adgroup from './zh-CN/adgroup';
import campaign from './zh-CN/campaign';
import common from './zh-CN/common';
import advertise from './zh-CN/advertise';
import copy from './zh-CN/copy';
export default {
  'navBar.lang': '语言',
  'layout.user.link.help': '帮助',
  'layout.user.link.privacy': '隐私',
  'layout.user.link.terms': '条款',
  'app.copyright.produced': '蚂蚁集团体验技术部出品',
  'app.preview.down.block': '下载此页面到本地项目',
  'app.welcome.link.fetch-blocks': '获取全部区块',
  'app.welcome.link.block-list': '基于 block 开发，快速构建标准页面',
  ...pages,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...table,
  ...strategy,
  ...awsome,
  ...user,
  ...message,
  ...home,
  ...privacy,
  ...ad,
  ...adgroup,
  ...campaign,
  ...common,
  ...advertise,
  ...copy,
};
