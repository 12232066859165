/*
 * @Description: Table header fields
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-12-05 17:54:36
 * @LastEditors: changfeng
 * @LastEditTime: 2023-05-23 10:58:13
 */
export default {
  'table.accountName': 'Account name',
  'table.accountAdvertise': 'Ad account ',
  'table.accountBind': 'Binding account',
  'table.accountRefresh': 'Refresh account',
  'table.operation': 'Operation',
  'table.breadcrums.createStrategy': 'Create strategy',

  'table.log.columns.advertiseID': 'Advertise ID',
  'table.log.columns.strategy': 'Stratgey',
  'table.log.columns.fixTime': 'Update time',
  'table.log.columns.remark': 'Remark',

  'table.template.columns.templateName': 'Template name',
  'table.log.columns.strategyID': 'Strategy ID',
  'table.log.columns.shareCode': 'Share code',


  'table.adTask.targetAccount' :'Target ad account',
  'table.adTask.targetCampaign': 'Campaign',
  'table.adTask.startTime' :'Start time',
  'table.adTask.endTime' :'End time',
  'table.adTask.status': 'Status',
  'table.adTask.status.Processing' :'Processing',
  'table.adTask.status.Terminated' :'Terminated',
  'table.adTask.errorMessage': 'Error',
  'table.operation.cancle.selection': 'Cancle',
  'table.operation.stop': 'stop',
  'table.operation.allStop': 'Batch stop',

  'table.selection.info.hasSelected': 'selected',
  'table.selection.info.items':'items'
  
};
