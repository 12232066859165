/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-02-22 10:31:52
 * @LastEditors: changfeng
 * @LastEditTime: 2023-08-11 10:03:46
 */
export default {
  'adGroup.title': '广告组',
  'adGroup.all': '不限',
  'adGroup.new adgroup': '新建广告组',
  'adGroup.new adgroup.edit': '编辑广告组',

  'adGroup.choose adgroup': '从已有中选择',
  'adGroup.use template': '使用模板',
  'adGroup.website.title': '网站',
  'adGroup.website.adgroup name': '广告组名称',
  'adGroup.website.adgroup name.edit': '修改广告组名称',

  'adGroup.website.adgroup name.look and edit': '查看并修改广告组名称',

  'adGroup.website.optimization': '优化事件',

  'adGroup.placement.title': '版位',
  'adGroup.placement type automatic': '系统自动为你选择支持的广告位流量。',
  'adGroup.placement type normal': '自主选择你心仪的广告位。',
  'adGroup.placement.automatic': '自动版位',
  'adGroup.placement.edit': '编辑版位',
  'adGroup.placement.user comment': '用户评论',
  'adGroup.placement.download video': '视频下载',
  'adGroup.placement.pangle disabled': 'Pangle媒体屏蔽列表',


  'adGroup.aco.title': '智能创意广告',
  'adGroup.aco.describe':
    '使用智能创意功能创建广告，自动生成广告创意丰富多样的视频，智能选择和焕新素材，确保你的广告组持续优化投放效果。',
  'adGroup.targeting.title': '定向',
  'adGroup.targeting.automatic': '自动定向',
  'adGroup.targeting.custom': '自定义定向',

  'adGroup.Demographics.title': '人群属性',
  'adGroup.Demographics.region': '地域',
  'adGroup.Demographics.language': '语言',
  'adGroup.Demographics.gender': '性别',
  'adGroup.Demographics.gender.male': '男性',
  'adGroup.Demographics.gender.female': '女性',
  'adGroup.Demographics.age': '年龄',
  'adGroup.Demographics.age.unit': '岁',
  'adGroup.Demographics.audience': '受众',
  'adGroup.Demographics.audience.include': '包含',
  'adGroup.Demographics.audience.exclude': '排除',

  'adGroup.interest.title': '兴趣和行为',
  'adGroup.interest.add interest': '添加兴趣',
  'adGroup.interest.add.label': '定义他们对那种类型的内容感兴趣',
  'adGroup.interest.videoaction.title': '添加行为：视频互动',
  'adGroup.interest.videoaction.type': '定义他们与视频的互动方式',
  'adGroup.interest.videoaction.watch end': '看完',
  'adGroup.interest.videoaction.like click': '点赞',
  'adGroup.interest.videoaction.comment': '评论',
  'adGroup.interest.videoaction.share': '分享',
  'adGroup.interest.videoaction.which video':
    '选择他们与哪些类别的视频有过互动',
  'adGroup.interest.videoaction.duration': '选择n天内发生的行为',
  'adGroup.interest.videoaction.duration.days': '天',

  'adGroup.interest.creatoraction.title': '添加行为：有以下与创作者互动行为',
  'adGroup.interest.creatoraction.type': '定义他们与创作者的互动方式',
  'adGroup.interest.creatoraction.followed': '关注',
  'adGroup.interest.creatoraction.viewed profile': '访问主页',
  'adGroup.interest.creatoraction.which creator':
    '选择他们与哪些类别的创作者有过互动',
  'adGroup.device.title': '设备',
  'adGroup.device.operating system': '操作系统',
  'adGroup.device.OS version': '系统版本',
  'adGroup.device.model': '设备机型',
  'adGroup.device.connection': '网络情况',
  'adGroup.device.carriers': '运营商',
  'adGroup.device.price': '设备价格',
  'adGroup.device.price.any': '不限',
  'adGroup.device.specific': '特定区间',
  'adGroup.expansion': '定向扩量',
  'adGroup.expansion.describe':
    '只有您选择了至少一个定向条件的设置才可以使用定向扩展。',
  'adGroup.expansion.audience': '受众',
  'adGroup.expansion.gender': '性别',
  'adGroup.expansion.age': '年龄',
  'adGroup.expansion.interest': '兴趣和行为',
  'adGroup.schedule&budget': '预算 & 排期',
  'adGroup.budget.daily': '日预算',
  'adGroup.budget.lifetime': '总预算',
  'adGroup.budget.nolimited': '不限预算',
  'adGroup.schedule.title': '排期',
  'adGroup.schedule.startTime': '从排定的开始时间起持续投放广告组',
  'adGroup.schedule.rangeTime': '在某个时间范围内投放广告组',
  'adGroup.schedule.rangetime.label': '日期时间',
  'adGroup.schedule.dayParting': '分时段',
  'adGroup.schedule.dayParting.all': '全天',
  'adGroup.schedule.dayParting.specific': '特定时段',

  'adGroup.Optimization.title': '出价&优化',
  'adGroup.Optimization.goal': '优化目标',
  'adGroup.Optimization.goal.conversion': '转化',
  'adGroup.Optimization.goal.click': '点击',
  'adGroup.Optimization.Bid strategy': '竞价策略',
  'adGroup.Optimization.cost cap': '成本上限',
  'adGroup.Optimization.lowest cost': '最低成本',

  'adGroup.billing event': '计费点',
  'adGroup.billevent.impression': '展现',
  'adGroup.delivery type': '投放速度',
  'adGroup.delivery type.standard': '标准',
  'adGroup.delivery type.speedup': '加速',

  'adGroup.message.no adGroup': '当前广告账户下没有广告组',
  'adGroup.message.exit aco': '当前广告账号下已存在智能创意广告',
  'adGroup.message.creating': '正在创建广告组',
  'adGroup.message.create adgroup success': '广告组创建成功',
  'adGroup.message.template name': '请输入广告组名称',
  'adGroup.message.template success': '模板创建成功',
  'adGroup.message.please enter adGroup name': '请输入广告组名称',
  'adGroup.message.please choose pixel': '请选择pixel',
  'adGroup.message.please choose placement': '请选择版位信息',
  'adGroup.message.Demographics.region': '请选择受众地区',
  'adGroup.message.action type': '请选择至少一个行为类型',
  'adGroup.message.action.category': '请选择至少一个分类',
  'adGroup.message.action.duration': '请选择一个时间',
  'adGroup.message.dateTimeRange': '请选择投放时间',
  'adGroup.message.optimization goal': '请选择优化目标',
  'adGroup.message.bid type custom': '请先设置广告组预算',
  'adGroup.message.conversionPrice': '请先设置出价',
  'adGroup.message.conversionLimited': '出价不能超过广告组预算值',
  // 'adGroup.message.':'',
  // 'adGroup.message.':'',
  // 'adGroup.message.':'',

  'adGroup.footer.button.template': '存为模板',
  'adGroup.footer.button.copy': '复制',

  'adGroup.tooltip.placement type automatic':
    '系统自动为你选择支持的版位流量。',
  'adGroup.tooltip.placement type normal': '自主选择你心仪的版位',
  'adGroup.tooltip.targeting.automatic':
    '根据推广目标、广告内容和过去的推广数据优化定向。',
  'adGroup.tooltip.targeting.custom':
    '手动设置关于要向哪类用户显示广告的条件。',
  'adGroup.tooltip.pay for ad': '决定何时支付广告费',
  'adGroup.tooltip.delivery type':
    '请在 24 小时内决定你的预算消耗方案和广告投放方案',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',

  'adGroup.content.max value': '最高价值',
  'adGroup.content.max value.describe': '充分消耗预算，获得最多的付费价值',
  'adGroup.content.pacing':
    '系统将在所选广告投放时间段根据业务访问量均匀匹配你的预算和广告。 访问量越高，广告投放频次越高，反之亦然。',

  'adGroup.formLabel.lowLimitedValue': '设置广告花费回报（ROAS）下限值',
  'adGroup.formLabel.choose.adGroup': '选择已有广告组',

  'adGroup.template.headTitle': '模板列表',
  'adGroup.template.use template': '使用',

  /** schedule日期表格 */

  'adGroup.schedule.week': '星期',
  'adGroup.schedule.time': '时间',
  'adGroup.schedule.week.Monday': '星期一',
  'adGroup.schedule.week.Tuesday': '星期二',
  'adGroup.schedule.week.Wednesday': '星期三',
  'adGroup.schedule.week.Thursday': '星期四',
  'adGroup.schedule.week.Friday': '星期五',
  'adGroup.schedule.week.Saturday': '星期六',
  'adGroup.schedule.week.Sunday': '星期日',
  'adGroup.schedule.drag to choice': '可拖动鼠标选择时间段',
  'adGroup.schedule.clear': '清空',

  /** pixel优化事件 */
  'adgroup.pixelevent.SHOPPING': '支付完成',
  'adgroup.pixelevent.ADD_BILLING': '添加支付信息',
  'adgroup.pixelevent.INITIATE_ORDER': '开始结账',
  'adgroup.pixelevent.ON_WEB_CART': '加入购物车',
  'adgroup.pixelevent.ON_WEB_DETAIL': '查看内容',
  'adgroup.pixelevent.ON_WEB_SEARCH': '搜索',
  'adgroup.pixelevent.BUTTON': '点击按钮',
  'adgroup.pixelevent.ON_WEB_ADD_TO_WISHLIST': '加入心愿单',
  'adgroup.pixelevent.ON_WEN_ORDER': '下单',
  'adgroup.pixelevent.CONSULT': '联系',
  'adgroup.pixelevent.DOWNLOAD_START': '下载',
  'adgroup.pixelevent.FORM': '提交表单',
  'adgroup.pixelevent.ON_WEB_REGISTER': '完成注册',
  'adgroup.pixelevent.ON_WEB_SUBSCRIBE': '订阅',


  /** 时区 */
  'adgroup.timezone':'广告基于你注册的账户时区进行投放'
};
