/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-12-08 10:47:46
 * @LastEditors: changfeng
 * @LastEditTime: 2023-07-26 17:31:57
 */
export default {
  'advertise.account': '广告账户',
  'advertise.account.placeholder': '请选择广告账户',
  'advertise.account.refresh': '刷新账号',

  'advertise.select.template': '选择模板',
  'advertise.promotion.series': '推广系列',
  'advertise.putin.time': '广告组开始投放时间',
  'advertise.video.material': '视频素材',
  'advertise.create.material': '新建一条素材',

};
