/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-01-03 11:10:51
 * @LastEditors: changfeng
 * @LastEditTime: 2024-04-24 14:01:01
 */

import { Reducer } from '@umijs/max';
import { MODEL } from './typings.d.ts';
import { CREATEAD } from '../pages/AdvertiseManarge/typings';

type AdList = {
  itemId: string | undefined;
  videoId: string | undefined;
  [propName: string]: any;
};

type State = {
  sourceCampaign: MODEL.Campaign;
  sourceAdvertiser: string;
  adGroupInfo: {
    campaignName: string;
    pixelId: string;
    startTime: string;
  };
  adList: AdList[];
  adOptionType: 'new' | 'edit' | 'delete';
  operationMaterial: CREATEAD.AdTypes;
  isAco: boolean;
  baseCopyInfo: any;
  acoAttribute: any;
  targetAdvertiser: string;
  copyCampaignTimezone: string;
  copyCampaignETCTimezone: string;
  copyCount: number;
  isDailyCopy: boolean;
  copyDay: number;
  everyDayCopyNumber: number;
};

type AdModal = {
  namespace: string;
  state: State;
  reducers: ReducerType;
};

type ReducerType = {
  setSourceCampaign: Reducer<State>;
  setSourceAdvertiser: Reducer<State>;
  setAdGroupInfo: Reducer<State>;
  setAdList: Reducer<State>;
  setAdOptionType: Reducer<State>;
  setEditAd: Reducer<State>;
  setDeleteAd: Reducer<State>;
  setOperationMaterial: Reducer<State>;
  setIsAco: Reducer<State>;
  setBaseCopyInfo: Reducer<State>;
  setAcoAttribute: Reducer<State>;
  setClearAttributr: Reducer<State>;
  setTargetAdvertiser: Reducer<State>;
  setCopyCampaignTimezone: Reducer<State>;
  setCopyCount: Reducer<State>;
  setDailyCopy: Reducer<State>;
  setCopyDay: Reducer<State>;
  setEveryDayCopyNumber: Reducer<State>;
  setIsMultipleCopy: Reducer<State>;
  setTargetAdvertiserList: Reducer<State>;
};
const ADMODAL: AdModal = {
  namespace: 'COPYCAMPAIGN',
  state: {
    sourceCampaign: {
      advertiserId: '',
      budget: 0,
      budgetMode: 'BUDGET_MODE_DAY',
      campaignId: '',
      campaignName: '',
      objectiveType: 'WEB_CONVERSIONS',
      operationStatus: '',
    },
    sourceAdvertiser: '',
    adGroupInfo: {
      campaignName: '',
      pixelId: '',
      startTime: '',
    },
    adList: [],
    adOptionType: 'new',
    operationMaterial: {
      adName: '',
      identityId: '',
      identityType: '',
      adText: '',
      callToAction: '',
      callToActionId: '',
      creativeAuthorized: false,
      imageIds: [],
      landingPageUrl: '',
      tiktokItemId: '',
      videoId: '',
      isSparks: false,
    },
    isAco: false,
    baseCopyInfo: {},
    acoAttribute: {
      actionGuid: 'optimization',
    },
    targetAdvertiser: '',
    targetAdvertiserList: [],
    copyCount: 1,
    copyCampaignTimezone: 'Asia/Shanghai',
    copyCampaignETCTimezone: 'Etc/GMT',
    isDailyCopy: false,
    everyDayCopyNumber: 0,
    isMultipleCopy: false,
  },
  // effects: {

  // },
  reducers: {
    setSourceCampaign: (state, { payload }) => {
      return {
        ...state,
        sourceCampaign: payload,
      };
    },
    setSourceAdvertiser: (state, { payload }) => {
      return {
        ...state,
        sourceAdvertiser: payload,
      };
    },
    setAdGroupInfo: (state, { payload }) => {
      return {
        ...state,
        adGroupInfo: payload,
      };
    },
    setAdList: (state, { payload }) => {
      const list = [];
      payload.forEach((item, index) => {
        item.index = index;
        list.push(item);
      });
      return {
        ...state,
        adList: list,
      };
    },
    setAdOptionType: (state, { payload }) => {
      return {
        ...state,
        adOptionType: payload,
      };
    },
    setEditAd: (state, { payload }) => {
      const list = [];
      state.adList.forEach((item, index) => {
        if (payload.index === index) {
          list.push(payload);
        } else {
          list.push(item);
        }
      });

      return {
        ...state,
        adList: list,
      };
    },
    setDeleteAd: (state, { payload }) => {
      return {
        ...state,
        adList: payload,
      };
    },
    setOperationMaterial: (state, { payload }) => {
      return {
        ...state,
        operationMaterial: payload,
      };
    },
    setIsAco: (state, { payload }) => {
      return {
        ...state,
        isAco: payload,
      };
    },
    setBaseCopyInfo: (state, { payload }) => {
      return {
        ...state,
        baseCopyInfo: payload,
      };
    },
    setAcoAttribute: (state, { payload }) => {
      return {
        ...state,
        acoAttribute: {
          ...state.acoAttribute,
          ...payload,
        },
      };
    },

    setClearAttributr: (state) => {
      return {
        ...state,
        acoAttribute: {
          actionGuid: 'optimization',
        },
      };
    },
    setTargetAdvertiser: (state, { payload }) => {
      return {
        ...state,
        targetAdvertiser: payload,
      };
    },

    setCopyCampaignTimezone: (state, { payload }) => {
      let timezone = '';
      if (payload.timezone.includes('Etc')) {
        timezone = payload.timezone;
      } else {
        timezone = payload.displayTimezone;
      }
      return {
        ...state,
        copyCampaignTimezone: payload.displayTimezone,
      };
    },
    setCopyCount: (state, { payload }) => {
      return {
        ...state,
        copyCount: payload,
      };
    },
    setDailyCopy: (state, { payload }) => {
      return {
        ...state,
        isDailyCopy: payload,
      };
    },
    setCopyDay: (state, { payload }) => {
      return {
        ...state,
        copyDay: payload,
      };
    },
    setEveryDayCopyNumber: (state, { payload }) => {
      return {
        ...state,
        everyDayCopyNumber: payload,
      };
    },
    setIsMultipleCopy: (state, { payload }) => {
      return {
        ...state,
        isMultipleCopy: payload,
      };
    },
    setTargetAdvertiserList: (state, { payload }) => {
      return {
        ...state,
        targetAdvertiserList: payload,
      };
    },
  },
};

export default ADMODAL;
