/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-02-10 15:06:16
 * @LastEditors: changfeng
 * @LastEditTime: 2023-02-20 17:23:32
 */
export default {
  'home.nav.home': '首页',
  'home.nav.product': '产品介绍',
  'home.nav.project': '客户案例',
  'home.nav.login': '立即登陆',
  'home.nav.register': '免费注册',
  'home.nav.privacy': '隐私条款',
  'home.sectionOne.title': '专注于TikTok的智能投放工具 ',
  'home.sectionOne.freeTry': '免费试用',
  'home.section2.title': '有这样的问题？',
  'home.section2.title1': '盯盘人力成本高,扩量效率低下',
  'home.section2.title2': '扩量效率低下',
  'home.section2.world':
    '通过NewRate Tool实现自动盯盘和预警监测, 高效止损、拓量。批量管理策略,提高广告投资回报率,减少管理广告的时间。',
  'home.section3.title': '产品介绍',
  'home.section3.title1': '智能托管账户',
  'home.section3.list1': '对接平台API接口，毫秒级响应 ',
  'home.section3.list2': '自定义搭建规则，制定多样化策略',
  'home.section3.list3': '自动触发关停，降低投放成本',
  'home.section3.list4': '自动回溯检测，提高计划容错率',
  'home.section4.img.title1': '美妆',
  'home.section4.img.subtitle1': '0201-测试1',
  'home.section4.title1': '批量搭建广告',
  'home.section4.list1': '实现1000+计划快速铺量',
  'home.section4.list2': '批量创建计划，同场景快速复制',
  'home.section4.list3': '差异化广告，确保计划间不抢量',
  'home.section5.title': '多维度数据分析',
  'home.section5.list1': '素材、计划、定向、文案等多维度可视化数据分析',
  'home.section5.list2': '多账号数据报表一键导出',
  'home.section5.list3': '投放效果实时追踪',
  'home.section5.list4': '数据报表时间粒度精确到分钟',
  'home.section6.title': '海量规则市场',
  'home.section6.list1': '千万消耗验证，经典规则分享',
  'home.section6.list2': '全类目规则覆盖，海量数据分析',
  'home.section6.list3': '精英规则搭建，小白一键使用',
  'home.section7.title': '案例分析',
  'home.section7.useTool': '使用NewRate Tool后',
  'home.section7.until': '倍',
  'home.section7.person1': '某独立站定制礼品类电商客户',
  'home.section7.person1.problem1':
    '1.账户，计划，素材量级大，需投放多个落地页',
  'home.section7.person1.problem2': '2.测试花费成本不好把控，计划凌晨没人看管',
  'home.section7.person1.resolve1':
    '1.批量搭建落地页转化跟踪，可从账户、系列等多维度分配转化跟踪，极大提高优化师投放效率',
  'home.section7.person1.resolve2':
    '2.以智能托管账户降低人工成本，设置条件自动触发关停，有效把控测试成本',
  'home.section7.person1.promote1.item': '运营人效提升',
  'home.section7.person1.promote1.value': '187',
  'home.section7.person1.promote2.item': '消耗增长',
  'home.section7.person1.promote2.value': '42',
  'home.section7.person2': '某小店美妆类电商客户',
  'home.section7.person2.problem1': '1.团队经验不足，投放数据不稳定',
  'home.section7.person2.problem2': '2.无效计划多，关停不及时，拉低账户ROAS',
  'home.section7.person2.resolve1': '1.海量规则市场支持一键使用',
  'home.section7.person2.resolve2':
    '2.智能监控账户，自动关停/调整预算，批量调整计划',
  'home.section7.person2.promote1.item': '运营人效提升',
  'home.section7.person2.promote1.value': '300',
  'home.section7.person2.promote2.item': 'ROAS提升',
  'home.section7.person2.promote2.value': '2.5',
  'home.section7.person3': '某工具类APP客户',
  'home.section7.person3.problem1': '1.广告账户多，管理困难',
  'home.section7.person3.problem2': '2.多账户、多维度数据统计麻烦',
  'home.section7.person3.resolve1':
    '1.批量搭建，调整广告账户、支持跨账号一键切换',
  'home.section7.person3.resolve2':
    '2.细化数据至项目、账号、计划数据、定向、文案、素材等多个维度，极大提高数据分析能力',
  'home.section7.person3.promote1.item': '搭建效率提升',
  'home.section7.person3.promote1.value': '8',
  'home.section7.person3.promote2.item': '素材测试量提升',
  'home.section7.person3.promote2.value': '40',
  'home.section8.title': '公司介绍',
  'home.section8.text':
    'NewRate是一家以技术作为驱动的科技创新公司，也是平台一级合作伙伴，为跨境企业提供全方位生态服务',
  'home.section8.form.title': '申请体验',
  'home.section8.form.label.name': '您的称呼',
  'home.section8.form.placeholder.name': '怎么称呼您',

  'home.section8.form.label.email': '注册邮箱',
  'home.section8.form.placeholder.email': '可联系的工作邮箱',

  'home.section8.form.label.tel': '手机号码',
  'home.section8.form.placeholder.tel': '常用的移动电话号码',

  'home.section8.form.label.company': '公司名称',
  'home.section8.form.placeholder.company': '项目对应的营业执照全称',

  'home.section8.form.label.business': '主营业务',
  'home.section8.form.placeholder.business': '独立站或TikTok小店',

  'home.section8.form.label.category': '主要类目',
  'home.section8.form.placeholder.category': '美妆/家居/玩具',

  'home.section8.form.label.agree': '已阅读并同意',
  'home.section8.form.label.privacy': '个人信息与隐私保护条约',
  'home.section8.form.submit': '预约试用',
};
