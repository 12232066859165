/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-12-08 17:29:38
 * @LastEditors: changfeng
 * @LastEditTime: 2022-12-13 17:12:10
 */
export default {
  'user.center': 'User center',
  'user.account.information': 'Account information',
  'user.nickname': 'nickname',
  'user.nickname.placeholder': 'Please enter nickname',
  'user.tiktokAppId': 'TikTok AppId',
  'user.tiktokAppId.placeholder': 'Please enter TikTok AppId',
  'user.TikTokAppSecret': 'TikTok AppSecret',
  'user.TikTokAppSecret.placeholder': 'Please enter TikTok AppId',
  /** 登录页面 */

  /** 注册页面 */
  'user.invitedCode.title': 'Invite code',
  'user.invitedCode.message': 'please enter your invite code',
  'user.invitedCode.placeholder': 'please enter your invite code',
};
