/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-02-13 14:00:52
 * @LastEditors: changfeng
 * @LastEditTime: 2023-02-13 14:36:36
 */
export default {
  'privacy.title': '网页隐私政策',
  'privacy.description':
    'NewRate Tool respects and protects the personal privacy of all users of the Services. In order to provide you with a more accurate and personalized service, NewRate Tool will use and disclose your personal information as set forth in this Privacy Policy. However, NewRate Tool will treat such information with a high degree of diligence and due care. Except as otherwise provided in this Privacy Policy, NewRate Tool will not disclose or provide this information to third parties without your prior consent, and NewRate Tool may update this Privacy Policy from time to time. By agreeing to the NewRate Tool Service Use Agreement, you are deemed to have agreed to this Privacy Policy in its entirety. This Privacy Policy is an integral part of the NewRate Tool Service Use Agreement.',
  'privacy.one.title': '1. Scope of application',
  'privacy.one.list1':
    '  1.   The personal registration information you provide when you register for a NewRate Tool account, as requested by NewRate Tool.',
  'privacy.one.list2':
    '  2.   When you use the NewRate Tool web services, or access the NewRate Tool web pages,the NewRate Tool automatically receives and records information on your browser and computer, including, but not limited to, data such as your IP address, type of browser, language used, date and time of access, information about hardware and software features, and a record of the Web pages you request.',
  'privacy.one.list3':
    '  3.    NewRate Tool obtains personal user data from business partners through lawful means. You understand and agree that the following information does not apply to this Privacy Policy:',
  'privacy.one.list3.tip1':
    '1.     The keyword information you enter when using the search services provided by the NewRate Tool platform.',
  'privacy.one.list3.tip2':
    '2.     Violations of law or violations of NewRate Tool rules and actions NewRate Tool has taken against you.',

  'privacy.two.title': '2. Use of information',
  'privacy.two.list1':
    '  1.   NewRate Tool will not provide, sell, rent, share or trade your personal information to any unrelated third party unless it has your prior permission or unless such third party and NewRate Tool (including NewRate Tool affiliates), individually or jointly, provide you with a service, and after such service ends, it will be denied access to all such information, including all such information to which it previously had access.',
  'privacy.two.list2':
    '  2.   NewRate Tool also does not allow any third party to collect, edit, sell or distribute your personal information by any means without compensation. If any NewRate Tool platform user engages in any of the above activities, NewRate Tool reserves the right to terminate the service agreement with that user immediately upon discovery.',
  'privacy.two.list3':
    '  3.  NewRate Tool may use your personal information to provide you with information that is of interest to you for the purposes of servicing users, including but not limited to sending you information about products and services, or sharing information with NewRate Tool partners so that they can send you information about their products and services (the latter requires your prior consent).',

  'privacy.three.title': '3. Disclosure of information',
  'privacy.three.subtitle':
    '  NewRate Tool will disclose your personal information, in whole or in part, in accordance with your individual wishes or as required by law, if.',
  'privacy.three.list1':
    '  1. Disclosure to third parties with your prior consent.',
  'privacy.three.list2':
    '  2.   It is necessary to share your personal information with third parties in order to provide the products and services you have requested.',
  'privacy.three.list3':
    '  3.   In accordance with the relevant provisions of the law, or the requirements of administrative and judicial bodies, disclosure to third parties or administrative and judicial bodies.',
  'privacy.three.list4':
    '  4.  If you violate any relevant Chinese laws or regulations or the NewRate Tool Service Agreement or related rules, you are required to disclose them to a third party.',
  'privacy.three.list5':
    "  5.   If you are an eligible IPR complainant and have filed a complaint, disclose to the Respondent, at the Respondent's request, so that the parties can address possible disputes over rights.",
  'privacy.three.list6':
    '  6.   If any party to a transaction created on the NewRate Tool platform fulfills or partially fulfills its obligations under the transaction and makes a request for disclosure of information, NewRate Tool reserves the right, at its discretion, to provide the user with the necessary information, such as the contact information of its counterparty, to facilitate the completion of the transaction or the resolution of the dispute.',
  'privacy.three.list7':
    '  7.   Other disclosures as deemed appropriate by law, regulation or website policy.',

  'privacy.four.title': '4. Information storage and exchange',
  'privacy.four.subtitle':
    'Information and materials collected about you by NewRate Tool will be stored on the servers of NewRate Tool and/or its affiliates and may be transferred to, accessed, stored and displayed outside of your country, region or location where NewRate Tool collects information and materials.',

  'privacy.five.title': '5. Use of cookies',
  'privacy.five.list1':
    '  1.   Where you have not rejected cookies, NewRate Tool may set or retrieve cookies on your computer to enable you to log in or use NewRate Tool Platform services or features that rely on cookies.NewRate Tool uses cookies to provide you with a more personalized service, including promotional services.',
  'privacy.five.list2':
    '  2.   You have the right to choose to accept or decline cookies, and you may refuse cookies by modifying your browser settings; however, if you choose to decline cookies, you may not be able to log in or use NewRate Tool web services or features that rely on cookies.',
  'privacy.five.list3':
    '  3.   This policy will apply to information obtained through cookies set by the NewRate Tool.',

  'privacy.six.title': '6.  Information Security',
  'privacy.six.list1':
    '  1.   NewRate Tool will ensure that your information is not lost, misused or altered by encrypting your password. Notwithstanding the aforementioned security measures, please note that there are no "perfect security measures" on the information network.',
  'privacy.six.list2':
    '  2.   When using the NewRate Tool Web Services to conduct online transactions, you will inevitably have to disclose your personal information, such as contact information or postal address, to counterparties or potential counterparties. Please protect your personal information and provide it to others only when necessary. If you discover that your personal information has been compromised, especially your NewRate Tool user name and password, please contact NewRate Tool Customer Service immediately so that NewRate Tool can take appropriate action.',
};
