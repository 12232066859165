/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-11-25 11:51:38
 * @LastEditors: changfeng
 * @LastEditTime: 2023-02-13 14:44:42
 */

import { Image } from 'antd';
import type { AutoCompleteProps } from 'antd/es/auto-complete';
import React from 'react';
import styles from './index.less';
import { history, useModel } from '@umijs/max';

export type HeaderSearchProps = {
  onSearch?: (value?: string) => void;
  onChange?: (value?: string) => void;
  onVisibleChange?: (b: boolean) => void;
  className?: string;
  placeholder?: string;
  options: AutoCompleteProps['options'];
  defaultVisible?: boolean;
  visible?: boolean;
  defaultValue?: string;
  value?: string;
};

const UserAvatar: React.FC = () => {
  const initialState = useModel('@@initialState');
  const {
    initialState: { currentUser },
  } = initialState;
  return (
    <div style={{ width: '90%',height: '90%' }}>
      <div
        className={styles.userInfo}
        onClick={() => history.push('/userCenter')}
      >
        <Image
          preview={false}
          className={styles.userAvatar}
          src="https://tiktok-ad-tool-1255795714.cos.ap-shanghai.myqcloud.com/icon_head%402x.c0f0a41a.png"
        />
        <h6>{currentUser?.nickname}</h6>
      </div>
    </div>
  );
};

export default UserAvatar;
