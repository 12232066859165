import { DefaultFooter } from '@ant-design/pro-components';

const Footer: React.FC = () => {
  const defaultMessage = '杭州新速率科技有限公司';

  const currentYear = new Date().getFullYear();

  return (
    <DefaultFooter
      style={{ background: 'transparent' }}
      copyright={`${currentYear} ${defaultMessage}`}
      links={[]}
    />
  );
};

export default Footer;
