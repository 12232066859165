/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-11-30 10:57:30
 * @LastEditors: changfeng
 * @LastEditTime: 2024-01-19 10:58:20
 */
export default {
  'menu.welcome': '欢迎',
  'menu.more-blocks': '更多区块',
  'menu.home': '首页',
  'menu.admin': '管理页',
  'menu.admin.sub-page': '二级管理页',
  'menu.login': '立即登录',
  'menu.register': '立即注册',
  'menu.register-result': '注册结果',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': '表单页',
  'menu.automation.strategy': '自动化策略',
  'menu.advertise.account': '广告账户',
  'menu.advertise.account.title': '广告账户',

  'menu.strategy.policy': '策略管理',
  'menu.strategy.market': '策略市场',

  'menu.strategy.template': '策略模板',
  'menu.advertise.management': '广告管理',
  'menu.advertise.create': '广告创建',
  'menu.advertise.template.create': '广告组模板',
  'menu.advertise.template.new': '新建模板',
  'menu.advertise.creation.task': '广告任务',
  'menu.asset.material.menerge': '素材管理',
  'menu.asset.menerge': '资产管理',
  'menu.asset.SparkAds binding': 'Spark Ads 批量绑定',




  'menu.data statistics': '数据统计',

  'menu.log': '运行日志',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人页',
  'menu.account.center': '个人中心',
  'menu.account.settings': '个人设置',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登录',
  'menu.language': '中文',
  'menu.retreat': '后退',
  'menu.submit': '提交',
  'menu.setting':'设置',
  /** 绑定账号 */
  'menu.bind.account': '绑定账号',
  'menu.refresh.account': '刷新账户',
  /** table Operation */
  'menu.table.operation.edit': '编辑',
  'menu.table.operation.copy': '复制',
  'menu.table.operation.share': '分享',
  'menu.table.operation.delete': '删除',
  'menu.table.operation.use': '使用',
  'menu.table.operation.addStrategy': '添加策略',
  'menu.table.operation.menergeStrategy': '管理策略',

  'menu.toolbar.create': '新建',

  'menu.invite.newUser': '邀请新用户',


  'menu.step.back': "返回",
  'menu.step.next': "下一步",
  'menu.step.submit':"提交"
};
