/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-11-14 13:35:36
 * @LastEditors: changfeng
 * @LastEditTime: 2023-12-19 15:32:24
 */
import React, { useState } from 'react';
import Footer from '@/components/Footer';
import UserAvatar from '@/components/UserAvatar';
import type { Settings as LayoutSettings } from '@ant-design/pro-components';
import type { RunTimeLayoutConfig } from '@umijs/max';
import { history, FormattedMessage, setLocale } from 'umi';
import { stringify } from 'querystring';
import defaultSettings from '../config/defaultSettings';
import { getCurrentUser } from './pages/services/services';
import type { RequestConfig } from 'umi';
import { notification, Button, message as Message, Card } from 'antd';
import { SelectLang } from 'umi';
import { LogoutOutlined, DownOutlined } from '@ant-design/icons';
import { getAccessToken, MoveTokenFromLocalStorage } from './utils/utils';
import Notification from '@/assets/notification_1.gif';
import './global.less';
import AnnouncementModal from '@/components/AnnouncementModal';
import HoverFloatingWindow from '@/components/HoverFloatingWindow';


import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
// 运行时配置

// 全局初始化数据配置，用于 Layout 用户信息和权限初始化
// 更多信息见文档：https://next.umijs.org/docs/api/runtime-config#getinitialstate
// const loginPath = '/user/login';
const loginPath = '/login';

const registerPath = '/register';

interface InitialStateType {
  [propName: string]: any;
}

const logout = async () => {
  // 删除cookie和token
  localStorage.setItem('Authorization', '');
  MoveTokenFromLocalStorage();
  const { query = {}, search, pathname } = history.location;
  const { redirect } = query; // Note: There may be security issues, please note

  if (window.location.pathname !== '/login' && !redirect) {
    history.replace({
      pathname: '/login',
      search: stringify({
        redirect: pathname + search,
      }),
    });
  }
};
/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */


/** 入口文件配置dayjs插件 */
dayjs.extend(utc);

dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(localeData);


/** 入口文件配置dayjs插件 */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: any;
  loading?: boolean;
  fetchUserInfo?: () => Promise<any>;
}> {
  const fetchUserInfo = async () => {
    try {
      const msg = await getCurrentUser();
      return msg.data;
    } catch (error) {
      // history.push(loginPath);
      localStorage.removeItem('Authorization');
      const { search, pathname } = history.location;

      console.log('pathname + search', pathname + search);
      history.replace({
        pathname: loginPath,
        search: stringify({
          redirect: pathname + search,
        }),
      });
    }
    return undefined;
  };
  // 如果不是登录页面，执行
  if (
    history.location.pathname !== loginPath &&
    history.location.pathname !== registerPath &&
    history.location.pathname !== '/privacy' &&
    history.location.pathname !== '/home' &&
    history.location.pathname !== '/'
  ) {
    const userInfo = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser: userInfo,
      settings: defaultSettings,
    };
  }
  return {
    fetchUserInfo,
    settings: defaultSettings,
  };
}

enum ErrorShowType {
  SILENT = 0,
  WARN_MESSAGE = 1,
  ERROR_MESSAGE = 2,
  NOTIFICATION = 3,
  REDIRECT = 9,
}

interface ResponseStructure {
  success: boolean;
  data: any;
  errorCode?: number;
  errorMessage?: string;
  showType?: ErrorShowType;
}

export const layout: RunTimeLayoutConfig = ({
                                              initialState,
                                            }: {
  initialState: InitialStateType;
  setInitialState: () => void;
}) => {
  const { currentUser, fetchUserInfo } = initialState;

  const [notification, setNotification] = useState(false);

  return {
    title: 'New Rate & Tik Tok',
    menuExtraRender: ({ collapsed }) => !collapsed &&
      <div style={{ width: '100%', height: '40%', marginTop: '10px' }}>
        <Card size="small" bordered={false}><AnnouncementModal /></Card>
        <UserAvatar />
      </div>,
    actionsRender: ({ collapsed }) =>
      collapsed
        ? []
        : [
          <div
            key="footer"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              ghost
              type="default"
              style={{ color: '#333' }}
              onClick={logout}
            >
              <LogoutOutlined /> <FormattedMessage id="menu.account.logout" />
            </Button>
            <HoverFloatingWindow />
            <SelectLang
              className="selectLang"
              placement="top"
              icon={
                <span className="language-icon">
                  <FormattedMessage id="menu.language" />
                  <DownOutlined />
                </span>
              }
              onItemClick={(params) => {
                setLocale(params.key);
              }}
            />
          </div>,

        ],
    menu: {
      defaultOpenAll: false,
    },
    // headerContentRender: () => <RightContent />,
    disableContentMargin: false,
    waterMarkProps: {
      content: currentUser?.name,
    },
    footerRender: () => {

      return    <div>
        {[3,4,8].includes(currentUser?.id) && (
          <div
          style={{
            position: 'fixed',
            bottom:'10px' ,
            right: '10px' ,
            background: '#fff',
            padding: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            borderRadius:'8px',
          }}
        >
          <div style={{ display: 'flex' }} onMouseEnter={() => {setNotification(true)}} onMouseLeave={() => {setNotification(false)}}>
            <img style={{ width: 100 }}  src={Notification} />
            <div style={{ display:'flex',flexDirection:'column',justifyContent:'space-evenly',opacity:notification?1:0 }}>
              <a >更新记录</a>
              {
                currentUser?.id === 8 && (<a href="https://hzxinsulv.feishu.cn/drive/folder/OSxifmMfPloK0jd6BuVc1levnhd" rel="noreferrer" target="_blank">需求分析</a>)
              }

              <a href="https://hzxinsulv.feishu.cn/docx/Hgy4dAeGJolmzVx55NQcD3WYntc" rel="noreferrer" target="_blank">使用说明</a>
            </div>
          </div>
        </div>)}
        <Footer />

      </div>;
    },

    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      // let auth =

      if (
        location.pathname !== loginPath &&
        location.pathname !== registerPath &&
        location.pathname !== '/home'
      ) {
        if (currentUser === undefined) {
          fetchUserInfo();
        }
      }
      if (
        location.pathname === loginPath ||
        location.pathname === registerPath
      ) {
        if (localStorage.getItem('Authorization')) {
          history.push('/tiktok/account');
        }
      }
    },
    menuHeaderRender: undefined,
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    // 增加一个 loading 的状态
    childrenRender: (children) => children,
    // {
    //   // if (initialState?.loading) return <PageLoading />;
    //   return (
    //     <>
    //        { children }

    //     </>
    //   );
    // },
    ...initialState?.settings,
  };
};

export const request: RequestConfig = {
  timeout: 300000,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },

  // other axios options you want
  errorConfig: {
    errorHandler(error: any, options: any) {
      if (options?.skipErrorHandler) throw error;
      const { response = {}, message } = error;
      const { status = null } = response;
      if (String(response) !== '{}' && response.status) {
        const errorText = response.statusText;
        const msg = response.data.message || message;
        // 401时回到登录页面
        if (status === 401) {
          notification.warn({
            message: '您未登录或登录信息已过期，请重新登陆!',
          });
          // 清除token
          MoveTokenFromLocalStorage();
          // ...跳转到登录页面。
          // history.push(loginPath)
          const { search, pathname } = history.location;
          history.replace({
            pathname: loginPath,
            search: stringify({
              redirect: pathname + search,
            }),
          });
        } else {
          notification.error({
            message: `请求错误 [${status}]: ${msg}`,
            description: errorText,
          });
        }
      } else if (String(response) === '{}') {
        notification.error({
          description: message || '您的网络发生异常，无法连接服务器',
          message: message || '网络异常',
        });
      } else {
        notification.error({
          description: message || '您的网络发生异常，无法连接服务器',
          message: message || '网络异常',
        });
      }
      error.response = {
        message: error.message,
      };
      Message.destroy();
      return Promise.reject(error);
    },

    errorThrower: (res: ResponseStructure) => {
      const { success, data, errorCode, errorMessage, showType } = res;
      if (!success) {
        const error: any = new Error(errorMessage);
        error.name = 'BizError';
        error.info = { errorCode, errorMessage, showType, data };
        throw error; // 抛出自制的错误
      }
    },
  },
  requestInterceptors: [
    (url, options) => {
      return {
        url,
        options: {
          ...options,
          headers: {
            Authorization: getAccessToken(),
          },
        },
      };
    },
  ],
  responseInterceptors: [
    (response: any) => {
      const { data = {} }: { url: string; status: number; data: any } =
        response;
      const { code, message } = data;
      /** 接口请求成功，做错误拦截 */
      if (code !== 200) {
        notification.info({
          message: message,
          description: data.data,
        });
        return {
          data: {
            success: true,
          },
        };
      }
      return response;
    },
  ],
};
