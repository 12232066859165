/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-12-08 16:28:14
 * @LastEditors: changfeng
 * @LastEditTime: 2022-12-14 14:16:03
 */
export default {
  AND: 'AND',
  OR: 'OR',
  NOT: 'NOT',
};
