// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/Users/zhengwei/xinsulv/tiktok-ad-tool-web/src/models/AD';
import model_2 from '/Users/zhengwei/xinsulv/tiktok-ad-tool-web/src/models/ADGroup';
import model_3 from '/Users/zhengwei/xinsulv/tiktok-ad-tool-web/src/models/Copy';
import model_4 from '/Users/zhengwei/xinsulv/tiktok-ad-tool-web/src/models/CopyCampaign';
import model_5 from '/Users/zhengwei/xinsulv/tiktok-ad-tool-web/src/models/Material';
import model_6 from '/Users/zhengwei/xinsulv/tiktok-ad-tool-web/src/models/rules';
import model_7 from '/Users/zhengwei/xinsulv/tiktok-ad-tool-web/src/models/Statistic';

export const models = {
model_1: { namespace: 'AD', model: model_1 },
model_2: { namespace: 'ADGroup', model: model_2 },
model_3: { namespace: 'Copy', model: model_3 },
model_4: { namespace: 'CopyCampaign', model: model_4 },
model_5: { namespace: 'Material', model: model_5 },
model_6: { namespace: 'rules', model: model_6 },
model_7: { namespace: 'Statistic', model: model_7 },
} as const
