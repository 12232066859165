/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-11-30 10:57:30
 * @LastEditors: changfeng
 * @LastEditTime: 2024-01-19 10:55:18
 */
export default {
  'menu.welcome': 'Welcome',
  'menu.more-blocks': 'More Blocks',
  'menu.home': 'Home',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Sub-Page',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.register-result': 'Register Result',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': 'Analysis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.automation.strategy': 'Automation strategy',
  'menu.advertise.account': 'AdvId',
  'menu.advertise.account.title': 'Account',

  'menu.strategy.policy': 'Policy strategy',
  'menu.strategy.market': 'Strategic market',

  'menu.strategy.template': 'Strategy template',
  'menu.advertise.management': 'Advertising management',
  'menu.advertise.create': 'Advertising creation',
  'menu.advertise.template.create': 'Template creation',
  'menu.advertise.template.new': 'New Template',
  'menu.data statistics': 'Statistics',
  'menu.advertise.creation.task': 'Advertising task',
  'menu.asset.material.menerge': 'Material Menerge',
  'menu.asset.menerge': 'Assets Menerge',
  'menu.asset.SparkAds binding': 'Bind Spark Ads ',

  'menu.log': 'Operation log',

  'menu.result.success': 'Success',
  'menu.result.fail': 'Fail',
  'menu.exception': 'Exception',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Trigger',
  'menu.account': 'Account',
  'menu.account.center': 'Account Center',
  'menu.account.settings': 'Account Settings',
  'menu.account.trigger': 'Trigger Error',
  'menu.account.logout': 'Logout',
  'menu.language': 'EN',
  'menu.retreat': 'Go back',
  'menu.setting':'Settings',

  /** 绑定账号 */
  'menu.bind.account': 'Binding Account',
  'menu.refresh.account': 'Sync Account',

  /** table Operation */
  'menu.table.operation.edit': 'Edit',
  'menu.table.operation.copy': 'Copy',
  'menu.table.operation.share': 'Share',
  'menu.table.operation.delete': 'Delete',
  'menu.table.operation.use': 'Use',
  'menu.table.operation.addStrategy': 'Add strategy',
  'menu.table.operation.menergeStrategy': 'Menergement strategy',

  'menu.toolbar.create': 'New',

  'menu.invite.newUser': 'Invite new user',

  'menu.step.back': "Back",
  'menu.step.next': "Next",
  'menu.step.submit':"Submit"
  


};
