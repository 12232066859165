/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-12-07 16:56:14
 * @LastEditors: changfeng
 * @LastEditTime: 2022-12-07 17:02:27
 */
export default {
  namespace: 'strategy',
  state: [],
  reducers: {
    addStrategy(state, { payload }) {
      return payload;
    },
  },
};
