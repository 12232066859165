/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-03-24 13:50:36
 * @LastEditors: changfeng
 * @LastEditTime: 2023-08-31 17:23:42
 */
export default {
  'campaign.title': '推广系列',
  'campaign.create.new': '新建系列',
  'campaign.select': '从已有中选择',
  'campaign.select.exiting': '选择已有推广系列',
  'campaign.name': '推广系列名称',
  'campaign.name.edit': '修改推广系列名称',
  'campaign.adgroup count.prefix': '源推广系列下有',
  'campaign.adgroup count.after':'个广告组',
  

  'campaign.target.webconversion': "品牌认知",
  'campaign.target.productsale': "商品销量",
  'campaign.target.productsale.describe': "销售来自你的 TikTok Shop、网站和应用的商品。",


  
  'campaign.set campaign budget': '设置推广系列预算',
  'campaign.set campaign budget on': '推广系列预算优化',

  'campaign.advertising.objective':'推广目标',
  'campaign.advertising.objective.website': '网站转化量',
  'campaign.advertising.objective.website.describe': '吸引用户在您的网站上执行更多有价值的操作。',


  'campaign.advertising.objective.Product sales': '商品销量',
  'campaign.advertising.objective.placeholder':'请选择推广目标',
  

  'campaign.Product source':'商品数据源',
  'campaign.Product source detail':'商品数据源详情',

  'campaign.Product source.placeholder':'请选择商品数据源',
  'campaign.Product source.catalog':'目录',
  'campaign.Product source.store':'TikTok Shop',


  'campaign.create success': '推广系列创建成功',
  'campaign.budget.day': '日预算',
  'campaign.budget.total': '总预算',
  'campaign.budget.placeholder': '50.00以上',

  'campaign.form.message.adAccount': '请选择广告账户',
  'campaign.form.message.please choose campaign': '请选择推广系列',
  'campaign.form.message.please input campaignName': '请输入推广系列名称',

  'campaign.form.message.budget': '请输入不小于50的数字',

  'campaign.name.look and edit': "查看并修改推广系列名称",
  'campaign.store.placeholder': '请选择店铺',

  'campaign.schedule.look and edit':"查看并修改广告组投放时间"
};
