/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-11-25 11:01:12
 * @LastEditors: changfeng
 * @LastEditTime: 2022-12-26 14:30:28
 */
import { request } from '@umijs/max';
/** 获取当前的用户 GET /api/currentUser */
export async function getCurrentUser() {
  return request<{
    data: API.CurrentUser;
  }>('/api/user/current_user', {
    method: 'GET',
  });
}

export const logout = (): void => {};

/** 获取当前的用户 GET /api/currentUser */
export async function currentUser(options?: { [key: string]: any }) {
  return request<{
    data: API.CurrentUser;
  }>('/api/user/current_user', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 退出登录接口 POST /api/login/outLogin */
export async function outLogin(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/login/outLogin', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 登录接口 POST /api/login/account */
// export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
//   return request<API.LoginResult>('/api/login/account', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     data: body,
//     ...(options || {}),
//   });
// }

/** 此处后端没有提供注释 GET /api/notices */
export async function getNotices(options?: { [key: string]: any }) {
  return request<API.NoticeIconList>('/api/notices', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取规则列表 GET /api/rule */
export async function rule(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('/api/rule', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 新建规则 PUT /api/rule */
export async function updateRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('/api/rule', {
    method: 'PUT',
    ...(options || {}),
  });
}

/** 新建规则 POST /api/rule */
export async function addRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('/api/rule', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 删除规则 DELETE /api/rule */
export async function removeRule(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/rule', {
    method: 'DELETE',
    ...(options || {}),
  });
}

// export async function accountList(options?: Record<string, any>) {
//   return request<API.Response<API.TiktokAccountListItem[]>>(`/apm/advertise/account`, {
//     method: 'GET',
//     ...(options || {}),
//   })
// }

export async function accountList(options?: Record<string, any>) {
  return request<API.Response<API.TableItem>>(`/api/tiktok/account`, {
    method: 'GET',
    ...(options || {}),
  });
}

export async function accountSync(options?: Record<string, any>) {
  return request<API.Response<undefined>>(`/api/tiktok/account_sync`, {
    method: 'GET',
    ...(options || {}),
  });
}

export async function identityVideoGet() {
  return request();
}
