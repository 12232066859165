/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-02-22 13:46:19
 * @LastEditors: changfeng
 * @LastEditTime: 2023-02-23 14:14:30
 */
export default {
  'common.no data': 'No data',
};
