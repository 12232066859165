/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-11-25 10:44:13
 * @LastEditors: changfeng
 * @LastEditTime: 2022-12-13 10:37:44
 */
import { Settings as LayoutSettings } from '@ant-design/pro-components';

const Settings: LayoutSettings & {
  pwa?: boolean;
  logo?: string;
} = {
  navTheme: 'light',
  // 拂晓蓝
  // primaryColor: '#13c2c2',
  layout: 'side',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: 'New Rate & Tik Tok',
  pwa: false,
  logo: 'https://tiktok-1251114352.cos.ap-shanghai.myqcloud.com/newrate-logo.png',
  iconfontUrl: '',
};

export default Settings;
