/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-02-22 11:02:34
 * @LastEditors: changfeng
 * @LastEditTime: 2023-02-22 11:03:46
 */
export default {
  'common.no data': '暂无数据',
};
