/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-12-08 10:47:46
 * @LastEditors: changfeng
 * @LastEditTime: 2023-07-26 16:54:32
 */
export default {
  'advertise.account': 'Ad Account',
  'advertise.account.placeholder': 'Please choose ad account',
  'advertise.account.refresh': 'refresh account',
  'advertise.select.template': 'please choose template',
  'advertise.promotion.series': 'campaign',
  'advertise.putin.time': 'start time',
  'advertise.video.material': 'video',
  'advertise.create.material': 'create',
};
