/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-01-03 11:10:51
 * @LastEditors: changfeng
 * @LastEditTime: 2023-10-25 10:37:03
 */

import { Reducer } from '@umijs/max';
import { Dayjs } from 'dayjs';
import moment from 'moment';

type State = {
  adGroupId: string;
  currentAdGroup: any;
  isAcoAd: boolean;
  startTime: string;
  endTime: string;
  templateLocations: Array<string>;
  currentTemplate: any;
  copyAdgroup: any;
  batchCopyAdGroupInfo: any;
  copyAdGroupNameList: { adgroupName: string, scheduleStartTime?: Dayjs, scheduleEndTime?: Dayjs }[];
  copyAdGroupIntervalTime:string | number,
};

type AdGroupModel = {
  namespace: string;
  state: State;
  reducers: ReducerType;
};

type ReducerType = {
  setIsAocAd: Reducer<State>;
  setAdGroupId: Reducer<State>;
  setAdGroupTime: Reducer<State>;
  setAdGroupLocations: Reducer<State>;
  setCurrentAdGroup: Reducer<State>;
  setCurrentTemplate: Reducer<State>;
  setCopyAdGroup: Reducer<State>;
  setBatchCopyAdGroupInfo: Reducer<State>;
  setCopyAdGroupNameList: Reducer<State>;
  setCopyAdGroupIntervalTime:Reducer<State>
};
const ADGROUPMODEL: AdGroupModel = {
  namespace: 'ADGROUP',
  state: {
    adGroupId: '',
    isAcoAd: false,
    startTime: '',
    endTime: '',
    templateLocations: [],
    currentAdGroup: {},
    currentTemplate: {},
    copyAdgroup: {},
    batchCopyAdGroupInfo: {},
    copyAdGroupNameList: [],
    copyAdGroupIntervalTime:0
  },
  // effects: {

  // },
  reducers: {
    setIsAocAd: (state, { payload }) => {
      return {
        ...state,
        isAcoAd: payload,
      };
    },

    setAdGroupId: (state, { payload }) => {
      return {
        ...state,
        adGroupId: payload,
      };
    },
    setAdGroupTime: (state, { payload }) => {
      return {
        ...state,
        adGroupId: payload,
      };
    },
    setAdGroupLocations: (state, { payload }) => {
      return {
        ...state,
        locations: payload,
      };
    },
    setCurrentAdGroup: (state, { payload }) => {
      return {
        ...state,
        currentAdGroup: payload,
      };
    },
    setCurrentTemplate: (state, { payload }) => {
      return {
        ...state,
        currentTemplate: payload,
      };
    },
    setCopyAdGroup: (state, { payload }) => {
      return {
        ...state,
        copyAdgroup: payload,
      };
    },
    setBatchCopyAdGroupInfo: (state, { payload }) => {
      return {
        ...state,
        batchCopyAdGroupInfo: payload,
      };
    },
    setCopyAdGroupNameList: (state, { payload }) => {
      const arr = []
      payload.forEach(item => {
        const { ...currentTime } = item

        console.log('currentTime',currentTime)
        arr.push({
          adgroupName: currentTime.adgroupName,
          scheduleStartTime: currentTime.scheduleStartTime,
          scheduleEndTime:currentTime?.scheduleEndTime
        })

      })
      console.log("modal中的广告组投放时间",arr)

      return {
        ...state,
        copyAdGroupNameList: [...arr],
      };
    },
    setCopyAdGroupIntervalTime: (state, { payload }) => {
      return {
        ...state,
        copyAdGroupIntervalTime:payload
      }
    }
  },
};

export default ADGROUPMODEL;
