import React, { useState } from 'react';
import { Modal, Button } from 'antd';

const AnnouncementModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="announcement">
      <span style={{ color: 'red' }}>
        为给您提供更稳定和优质的服务，我们将进行网站升级和迁移。
      </span>
      <Button type="link" onClick={showModal}>
        点击查看详情【7月1日更新】
      </Button>
      <Modal
        title={<div style={{ textAlign: 'center' }}>网站迁移公告</div>}
        visible={isModalVisible}
        onOk={handleOk}
        closable={false}
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            确认
          </Button>,
        ]}
      >
        <p>尊敬的客户：</p>
        <p>新版本内测报名已结束，感谢大家报名参与！如有需要使用新版本工具，可登录<a href={'https://www.adsurgebot.com'} target={'_blank'}>https://www.adsurgebot.com</a>进行注册</p>
        <p>此外，自7月16日起，当前工具将：</p>
        <ul>
          <li>不再提供稳定性保障</li>
          <li>不再提供问题修复及时性保障</li>
          <li>不再进行新功能更新</li>
          <li>不再支持同步非代理商New Rate所开通的广告账户</li>
          <li>策略执行频率：可选时间频率变更为固定为15分钟/次</li>
        </ul>
        <p>关于新版本工具：</p>
        <ol>
          <li>
            <p>内测已发放的套餐权益不受影响</p>
            <p>· 例如6月30日报名内测，赠送1个月专业版套餐，可正常使用到7月30日</p>
          </li>
          <li>
            内测用户赠送的套餐到期前可以自行选择标准版、专业版套餐进行订阅。提前订阅的套餐权益将在内测权益到期后开始生效。
          </li>
        </ol>
        <p>
          如有疑问请联系客服人员，联系方式：15258691811。
        </p>
        <div style={{ textAlign: 'center' }}>
          <img
            src="https://tiktok-ad-tool-1255795714.cos.ap-shanghai.myqcloud.com/home_page/企业微信二维码.jpeg"
            alt="企业微信二维码"
            style={{ maxWidth: '50%', height: 'auto' }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AnnouncementModal;
