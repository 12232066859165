/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-01-03 11:10:51
 * @LastEditors: changfeng
 * @LastEditTime: 2023-03-17 15:09:37
 */

import { Reducer } from '@umijs/max';

type VideoMaterialType = {
  postUrl: string;
  url: string;
  text: string;
  videoId: string;
  [propName: string]: any;
};

type IdentityType = {
  displayName: string;
  identityId: string;
  identityType: string;
  profileImage: string;
};
type Material = {
  isEmpty: boolean;
  isSparkAd: boolean;
  isCopy: boolean;
  itemId: string | undefined;
  videoId: string | undefined;
  index: number | 'NaN';
  [propName: string]: any;
};
type Campaign = {
  Objective: string;
  campaignId: string;
  campaignType: 'exist';
};

type State = {
  campaign: Campaign;
  copyCount: number;
  adGroupList: any[];
  isCopyAco: boolean;
};

type AdModal = {
  namespace: string;
  state: State;
  reducers: ReducerType;
};

type ReducerType = {
  setCampaign: Reducer<State>;
  setCopyCount: Reducer<State>;
  setAdGroupList: Reducer<State>;
  setIsCopyAcoAd: Reducer<State>;
};
const ADMODAL: AdModal = {
  namespace: 'COPY',
  state: {
    campaign: {
      campaignId: '',
      Objective: '',
      campaignType: 'exist',
    },
    copyCount: 1,
    adGroupList: [],
    isCopyAco: false,
  },
  // effects: {

  // },
  reducers: {
    setCampaign: (state, { payload }) => {
      return {
        ...state,
        campaign: payload,
      };
    },
    setCopyCount: (state, { payload }) => {
      return {
        ...state,
        copyCount: payload,
      };
    },
    setAdGroupList: (state, { payload }) => {
      return {
        ...state,
        adGroupList: payload,
      };
    },
    setIsCopyAcoAd: (state, { payload }) => {
      return {
        ...state,
        isCopyAco: payload,
      };
    },
  },
};

export default ADMODAL;
