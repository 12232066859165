/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-12-05 17:52:11
 * @LastEditors: changfeng
 * @LastEditTime: 2023-05-23 10:58:23
 */
export default {
  'table.accountName': '账号名称',
  'table.accountAdvertise': '广告账户',
  'table.accountBind': '绑定账号',
  'table.accountRefresh': '刷新账户',
  'table.account.title': '标题',
  'table.operation': '操作',
  'table.breadcrums.createStrategy': '新建策略',
  'table.log.columns.advertiseID': '广告ID',
  'table.log.columns.strategy': '策略',
  'table.log.columns.strategyID': '策略ID',
  'table.log.columns.shareCode': '分享码',

  'table.log.columns.fixTime': '修改时间',
  'table.log.columns.remark': '备注',

  'table.template.columns.templateName': '模板名称',

  'table.adTask.targetAccount' :'目标账户',
  'table.adTask.targetCampaign': '推广系列',
  'table.adTask.startTime' :'开始时间',
  'table.adTask.endTime' :'结束时间',
  'table.adTask.status' :'任务状态',
  'table.adTask.status.Processing' :'进行中',
  'table.adTask.status.Terminated' :'已终止',


  'table.adTask.errorMessage': '错误信息',
  
  'table.operation.cancle.selection': '取消选择',
  'table.operation.stop': '终止',
  'table.operation.allStop': '批量终止',
  'table.selection.info.hasSelected': '已选',
  'table.selection.info.items':'项'

  

};
