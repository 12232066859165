/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-12-08 17:29:38
 * @LastEditors: changfeng
 * @LastEditTime: 2022-12-12 10:54:57
 */
export default {
  'user.center': '个人中心',
  'user.account.information': '账户信息',
  'user.nickname': '昵称',
  'user.nickname.placeholder': '请输入昵称',
  'user.tiktokAppId': 'TikTok AppId',
  'user.tiktokAppId.placeholder': '请输入 TikTok AppId',
  'user.TikTokAppSecret': 'TikTok AppSecret',
  'user.TikTokAppSecret.placeholder': '请输入 TikTok AppId',
  /** 登录页面 */

  /** 注册页面 */
  'user.invitedCode.title': '邀请码',
  'user.invitedCode.message': '请输入邀请码',
  'user.invitedCode.placeholder': '请输入您的邀请码',
};
