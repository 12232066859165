/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-02-06 18:40:35
 * @LastEditors: changfeng
 * @LastEditTime: 2024-03-07 18:19:50
 */
export default function (initialState) {
  const { REACT_APP_ENV } = process.env;

  const { currentUser } = initialState

  return {
    showCreateAd: localStorage.getItem('Visited') === 'true',
    showStatistic: localStorage.getItem('Statistic') === 'true',
    showIllustrate: [3, 4, 8].includes(currentUser?.id),
    assetMenerge: currentUser?.role === "1",
    materialOptimization:currentUser?.role === "1"
    
  };
}
