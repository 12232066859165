/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-03-20 15:49:58
 * @LastEditors: changfeng
 * @LastEditTime: 2023-07-27 11:03:42
 */
export default {
  'copy.campaign.target': 'Targeting',
  'copy.copyTo': 'Copy to',
  'copy.campaign.exist': 'Existing',
  'copy.campaign.new': 'New',
  'copy.campaign.choose campaign': 'please choose campaign',
  'copy.campaign.target advertise': "Ad account",
  'copy.campaign.target advertise.placeholder': "please choose ad account ",
  'copy.campaign.copy count': "Count",
  
  'copy.copy': 'Copy',
  'copy.use all': "Use to all",
  'copy.adgroup.title':"Copy ad group",
  'copy.campaign.title': "Copy campaign",
  'copy.campaign.message.cbo.info':'The source promotion series and target promotion series budget attributes are inconsistent and cannot be replicated',
  
  'copy.edit.title':"copy "


};
