/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-11-28 16:19:29
 * @LastEditors: changfeng
 * @LastEditTime: 2023-12-11 16:41:47
 */
export function getQueryVariable(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return undefined;
}

export const SaveTokenToLocalStorage = (token: string) => {
  localStorage.setItem('Authorization', token);
};

export const MoveTokenFromLocalStorage = (): void => {
  localStorage.setItem('Authorization', '');
};

export const getAccessToken = (): string => {
  return localStorage.getItem('Authorization');
};

// 获取最大公约数
function getGcd(a, b) {
  let n1, n2;
  if (a > b) {
    n1 = a;
    n2 = b;
  } else {
    n1 = b;
    n2 = a;
  }

  const remainder = n1 % n2;
  if (remainder === 0) {
    return n2;
  } else {
    return getGcd(n2, remainder);
  }
}
/** 获取上传视频的宽高比利 */

export async function getVideoScale(url) {
  const video = document.createElement('video');
  video.setAttribute('width', '10');
  video.setAttribute('height', '10');
  video.setAttribute(
    'style',
    'position: absolute;opacity: 0;z-index: -5;top: 0;',
  );
  video.setAttribute('src', url);

  document.body.appendChild(video);

  const p = new Promise(function (resolve, reject) {
    video.addEventListener('canplay', function (e) {
      const gcd = getGcd(e.target.videoWidth, e.target.videoHeight);
      resolve([e.target.videoWidth / gcd, e.target.videoHeight / gcd]);
    });
  });
  const res = await p;
  document.body.removeChild(video);
  return res;
}


export  const debounce = function(func, wait, immediate) {
	// 设置定时器
	let timeout;
	return (...args) => {
		const later = () => {
			timeout = null;
			if (!immediate) func.apply(this, args);
		};
		const callNow = immediate && !timeout;
		// 进入先清除定时器 
		clearTimeout(timeout);
		// 重新设置一个定时器，如果没有连续触发函数，就执行定时器，也是就是核心原理
		timeout = setTimeout(later, wait);
		if (callNow) 
			func.apply(this, args);
	}
}