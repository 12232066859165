/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-01-03 11:10:51
 * @LastEditors: changfeng
 * @LastEditTime: 2024-03-15 11:20:28
 */

import { Reducer } from '@umijs/max';

type VideoMaterialType = {
  postUrl: string;
  url: string;
  text: string;
  videoId: string;
  [propName: string]: any;
};

type IdentityType = {
  displayName: string;
  identityId: string;
  identityType: string;
  profileImage: string;
};
type Material = {
  isEmpty: boolean;
  isSparkAd: boolean;
  type: 'library' | 'upload';
  isCopy: boolean;
  itemId: string | undefined;
  videoId: string | undefined;
  index: number | 'NaN';
  [propName: string]: any;
};

type State = {
  adAccount: string; // 广告账户
  currentCampaign: {
    campaignId: string;
    [propName: string]: any;
  }; // 当前选中和操作的推广系列

  adGroupId: string; // 当前广告组Id
  isAcoAd: boolean; // 标识是否是在创建ACO广告
  videoMaterial: Array<VideoMaterialType>; //创建customed广告时选中的视频素材
  currentMaterial: VideoMaterialType | Record<string, unknown>; //当前操作的视频素材
  currentUploadVideo: VideoMaterialType | Record<string, unknown>; // 当前选中的上传的本地素材

  identity: IdentityType | Record<string, unknown>; // 广告发布省份
  uploadVideo: any; // 上传的本地视频素材的列表
  videoMaterialType: 'library' | 'upload' | ''; // 视频来源，素材库选择，或手动上传
  selectedSparkList: Array<VideoMaterialType>;
  currentSpark: VideoMaterialType | Record<string, unknown>;
  isSparkAds: boolean;

  replaceMaterial: any;
  isReplaceMaterial: boolean;

  //复制广告组信息相关
  fromCGAd: Material[]; // 复制广告组下的所有广告
  adMaterialList: Material[];
  curOptonMaterial: Material;

  /** ACO广告相关信息 */
  ACOMaterial: Material[];
  ACOText: Array<string>;

  /** 复制ACO广告组时存储公共的属性值 */
  copyACOCommonAttr: any;

  /** 存储ACO的广告list */
  copyACOList: any;

  /** 广告创建成功之后，控制清除所有表单信息 */
  clearCampaignForm: boolean;
  clearAdGroupForm: boolean;
  clearAdForm: boolean;
  timezone: string;
  ETCTimeZone: string;

  /** 推广系列相关 */
  promotionObjective: 'WEB_CONVERSIONS' | 'PRODUCT_SALES' | null;

  /** 创建TT店铺广告时，存储店铺信息 */
  storeInfo: any;

  /** 创建广告时统一提交广告组和广告信息 */

  /** 创编类型 */
  creativeType: 'effect' | 'adgroup' | 'materisl';
};

type AdModal = {
  namespace: string;
  state: State;
  reducers: ReducerType;
};

type ReducerType = {
  setCurrentAdAccount: Reducer<State>;
  setCurrentCampaign: Reducer<State>;
  setIsAcoAd: Reducer<State>;
  setCurrentOptionMaterial: Reducer<State>;
  setIdentity: Reducer<State>;
  setAdGroupId: Reducer<State>;

  setIsSparkAds: Reducer<State>;
  setSelectMaterialList: Reducer<State>;
  // setEditMaterialAdText:Reducer<State>,
  setVideoMaterialType: Reducer<State>;
  setDeleteMaterial: Reducer<State>;

  setSelectedSparkList: Reducer<State>;
  setCurrentSpark: Reducer<State>;
  // setDeleteSpark:Reducer<State>,

  setUpoloadVideo: Reducer<State>;
  // setDeleteUploadVideo:Reducer<State>,

  setIsReplaceMaterial: Reducer<State>;
  setReplaceMaterial: Reducer<State>;

  setAdMaterialList: Reducer<State>;
  setClearMaterialList: Reducer<State>;

  /** ACO相关 */
  setACOMaterial: Reducer<State>;
  setDeleteACOMaterial: Reducer<State>;
  setClearACOMaterial: Reducer<State>;
  setACOText: Reducer<State>;
  setCopyACOList: Reducer<State>;

  /** 复制广告素材 */
  setCopyAdMaterial: Reducer<State>;
  setCopyACOCommonAttr: Reducer<State>;
  setCampaignFormClear: Reducer<State>;
  setAdGroupFormClear: Reducer<State>;
  setAdFormClear: Reducer<State>;
  setTimezone: Reducer<State>;
  setETCTimezone: Reducer<State>;

  /** 推广目标 */
  setPromotionObjective: Reducer<State>;

  /** StoreInfo */
  setStoreInfo: Reducer<State>;
  setCreativeType: Reducer<State>;
};
const ADMODAL: AdModal = {
  namespace: 'Material',
  state: {
    adAccount: '',
    currentCampaign: {
      campaignId: '',
    },
    adGroupId: '',
    isAcoAd: false,
    videoMaterial: [], //选中的视频素材
    currentMaterial: {},
    identity: {},
    uploadVideo: [],
    videoMaterialType: '',
    currentSpark: {},
    selectedSparkList: [],
    isSparkAds: false,
    currentUploadVideo: {},
    replaceMaterial: null,
    isReplaceMaterial: false,
    fromCGAd: [],
    adMaterialList: [
      {
        isCopy: false,
        isEmpty: true,
        isSparkAd: false,
        itemId: undefined,
        videoId: undefined,
        index: 'NaN',
        type: 'library',
      },
    ],
    curOptonMaterial: {
      isCopy: false,
      isEmpty: true,
      isSparkAd: false,
      itemId: undefined,
      videoId: undefined,
      index: 'NaN',
      type: 'library',
      actionGuid: 'optimization',
    },
    ACOMaterial: [],
    ACOText: [''],
    copyACOCommonAttr: {},
    copyACOList: {},
    clearCampaignForm: false,
    clearAdGroupForm: false,
    clearAdForm: false,
    timezone: 'Asia/Shanghai',
    ETCTimeZone: 'Asia/Shanghai',
    promotionObjective: '',
    creativeType: '',
    currentAdGroup: {},
  },
  // effects: {

  // },
  reducers: {
    setCurrentAdAccount: (state, { payload }) => {
      state.adAccount = payload;
      return {
        ...state,
      };
    },
    setCurrentCampaign: (state, { payload }) => {
      return {
        ...state,
        currentCampaign: payload,
      };
    },
    setIsAcoAd: (state, { payload }) => {
      return {
        ...state,
        isAcoAd: payload,
      };
    },
    setSelectMaterialList: (state, { payload }) => {
      if (!payload.length) {
        return {
          ...state,
          videoMaterial: [
            {
              isCopy: false,
              isEmpty: true,
              isSparkAd: false,
              itemId: undefined,
              videoId: undefined,
              index: 'NaN',
            },
          ],
          // currentMaterial:{},  // 默认预览框中显示第一个视频素材
        };
      } else {
        /** 此处应做兼容处理，再次选择时videoMaterial中不一定还包含currentMaterial */
        const current = state.curOptonMaterial;
        // if(payload.filter(item => item.videoId).indexOf(current.videoId) < 0){
        //     current = payload[0]
        // }
        /** 替换adMaterial中的videoMaterial */
        /** 去除首个空值 */
        const list = state.adMaterialList;
        payload.forEach((item) => {
          if (!item.isEmpty && !item.isCopy && !item.isSparkAd) {
            let index = null;
            const res = state.adMaterialList.filter((n, ind) => {
              if (n.videoId === item.videoId) {
                index = ind;
                return n;
              }
              return null;
            });
            if (res.length) {
              list[index] = item;
            } else {
              list.push(item);
            }
          }
          //    else{
          //     list.push(item)
          //    }
        });

        /** 去除空值后再排序 */
        const arr = [];
        list.forEach((item, index) => {
          if (item.index === 'NaN' || item.isEmpty === true) {
            return;
          }
          item['index'] = index;
          arr.push(item);
        });
        if (arr.length === 0) {
          arr.push({
            ...payload,
            isCopy: false,
            isEmpty: true,
            isSparkAd: false,
            itemId: undefined,
            videoId: undefined,
            index: 'NaN',
            actionGuid: 'optimization',
          });
        }

        /** videoMaterial 去重合并 */
        const newVideoList = Array.from(
          new Set([...state.videoMaterial, ...payload]),
        );
        return {
          ...state,
          videoMaterial: newVideoList,
          currentMaterial: current || payload[0], // 默认预览框中显示第一个视频素材
          adMaterialList: arr,
          curOptonMaterial: current.isEmpty ? arr[0] : current,
        };
      }
    },

    setDeleteMaterial: (state, { payload }) => {
      // 点击删除按钮首先得触发
      console.log('payload', payload);
      let current = state.currentMaterial;
      const material = state.adMaterialList;
      let videoList = state.videoMaterial;
      let ACOMaterial = [];
      let arr = [];
      material.forEach((item) => {
        if (item.index !== payload.index) {
          arr.push(item);
        }
      });

      console.log('isState', state);
      if (state.isAcoAd && state.isSparkAds) {
        ACOMaterial = state.ACOMaterial.filter(
          (item) => item.itemId !== payload.ItemId,
        );
      }
      /** 如果是创建ACO广告，则同步修改 videoMaterial列表 */
      if (payload.isAco) {
        videoList = videoList.filter(
          (item) => item.videoId !== payload.videoId,
        );
        ACOMaterial = state.ACOMaterial.filter(
          (item) => item.videoId !== payload.videoId,
        );
      }

      if (arr.length === 0) {
        current = {
          isCopy: false,
          isEmpty: true,
          isSparkAd: false,
          itemId: undefined,
          videoId: undefined,
          index: 'NaN',
          videoMaterialType: '',
          actionGuid: 'optimization',
        };
        arr.push(current);
      } else {
        arr = arr.map((item, index) => {
          item.index = index;
          return item;
        });
        current = { ...arr[0] };
      }

      /** 删除素材之后，指定当前的curoptionMaterial */
      return {
        ...state,
        ACOMaterial: ACOMaterial,
        videoMaterial: videoList,
        currentMaterial: current,
        adMaterialList: arr,
        curOptonMaterial: current,
      };
    },
    setCurrentOptionMaterial: (state, { payload }) => {
      return {
        ...state,
        // currentMaterial:payload,
        curOptonMaterial: payload,
      };
    },
    setIdentity: (state, { payload }) => {
      return {
        ...state,
        identity: payload,
      };
    },
    setUpoloadVideo: (state, { payload }) => {
      // let leftPayload = []
      /** 选中视频上传之后，应该去重 */
      // payload.forEach(item => {
      //     origin.forEach(n => {
      //         if(n.videoId === item.videoId){
      //             item.repeat = true
      //         }else{
      //             item.repeat = false
      //         }
      //         leftPayload.push(item)
      //     })
      // })

      const arr = [...payload].filter((item) => item.index !== 'NaN');
      const result = [];
      arr.forEach((item, index) => {
        item.index = index;
        result.push(item);
      });
      if (!result.length) {
        result.push({
          isCopy: false,
          isEmpty: true,
          isSparkAd: false,
          itemId: undefined,
          videoId: undefined,
          index: 'NaN',
          actionGuid: 'optimization',
        });
      }
      return {
        ...state,
        uploadVideo: payload,
        currentUploadVideo: payload[0] || {},
        // videoMaterial:payload
        // adMaterialList:payload.length ? payload : [{
        //     isCopy:false,
        //     isEmpty:true,
        //     isSparkAd:false,
        //     itemId:undefined,
        //     videoId:undefined,
        //     index:'NaN'
        // }],
        curOptonMaterial: result[0],
        adMaterialList: result,
      };
    },
    // setDeleteUploadVideo:(state, {payload}) => {
    //     if(!payload.length){
    //         return {
    //             ...state,
    //             uploadVideo:payload,
    //             currentUploadVideo: {},
    //         }
    //     }else{
    //         let current = state.currentUploadVideo
    //         if(payload.filter(item => item.videoId).indexOf(current.videoId) < 0){
    //             current = payload[0]
    //         }
    //         return {
    //              ...state,
    //              uploadVideo:payload,
    //              currentUploadVideo:current,
    //         }
    //     }
    // },
    setAdGroupId: (state, { payload }) => {
      return {
        ...state,
        adGroupId: payload,
      };
    },
    setVideoMaterialType: (state, { payload }) => {
      return {
        ...state,
        videoMaterialType: payload,
      };
    },
    setIsSparkAds: (state, { payload }) => {
      return {
        ...state,
        isSparkAds: payload,
      };
    },
    setSelectedSparkList: (state, { payload }) => {
      let current = state.curOptonMaterial;
      // if(Object.keys(current).length === 0 || current.index === "NaN"){
      //     current = payload[0]
      // }
      /** 重复提交之后去重 */
      /** 去除空的item ，index == NaN */

      //   let arr = Array.from(new Set([...payload]));
      let list = state.adMaterialList.filter((item) => item.index !== 'NaN');
      list = [...list, ...payload];

      /** 不用去重 */

      /** 每次修改后，重新排列序号 */
      const sortList = list.map((item, index) => {
        item.index = index;
        if (item.itemId === current?.itemId) {
          current = item;
        }
        return item;
      });
      if (current.index === 'NaN') {
        current = sortList[0];
      }

      return {
        ...state,
        videoMaterial: payload,
        currentSpark: payload[0], // 默认预览框中显示第一个视频素材
        adMaterialList: sortList,
        // curOptonMaterial:state.curOptonMaterial.isEmpty ? payload[0] : state.curOptonMaterial,
        curOptonMaterial: current,
      };
    },
    setCurrentSpark: (state, { payload }) => {
      return {
        ...state,
        currentSpark: payload,
        // curOptonMaterial:payload
      };
    },
    // setDeleteSpark:(state,{payload}) => {
    //     let current = state.currentSpark

    //     if(payload.length === 0){
    //         current = {};
    //     }else{
    //         if(payload.filter(item => item.videoId).indexOf(current.videoId) < 0){
    //             current = payload[0]
    //         }
    //     }
    //     return {
    //         ...state,
    //         selectedSparkList:payload,
    //         currentMaterial:current,
    //     }
    // },
    setIsReplaceMaterial: (state, { payload }) => {
      return {
        ...state,
        isReplaceMaterial: payload,
      };
    },
    setReplaceMaterial: (state, { payload }) => {
      //** 筛选删除当前的，替换为新选中的 */
      let current = state.curOptonMaterial;
      const list = state.adMaterialList;

      const index = current.index;
      payload.index = index;
      list[index] = payload;
      current = payload;

      /** 替换之后重排序 */
      //  let sortList = list.map((item,index) => item.index = index)
      return {
        ...state,
        currentMaterial: current,
        // videoMaterial:[...list],
        isReplaceMaterial: false,
        adMaterialList: list,
        curOptonMaterial: current,
      };
    },
    setAdMaterialList: (state, { payload }) => {
      const arr = payload.map((item, index) => {
        if (item.index === 'NaN') {
          return item;
        } else {
          item.index = index;
          return item;
        }
      });

      /** 添加素材排序 */
      const current = state.curOptonMaterial;
      if (arr.length === 0) {
        // arr.push({
        //   isCopy: false,
        //   isEmpty: true,
        //   isSparkAd: false,
        //   itemId: undefined,
        //   videoId: undefined,
        //   index: 'NaN',
        // });
      }
      return {
        ...state,
        curOptonMaterial:
          current.isEmpty || !Object.keys(current).length ? arr[0] : current,
        adMaterialList: arr,
      };
    },
    setClearMaterialList: (state) => {
      const current = {
        isEmpty: true,
        isCopy: false,
        isSparkAd: false,
        index: 'NaN',
        itemId: '',
        videoId: '',
        actionGuid: 'optimization',
      };
      return {
        ...state,
        curOptonMaterial: current,
        adMaterialList: [current],
      };
    },
    setACOMaterial: (state, { payload }) => {
      /** 素材改变，计算后，加入adMaterialList */
      const text = state.ACOText;
      let list = [...state.adMaterialList].filter((n) => n.index !== 'NaN');
      let ind = 0;
      let current = state.curOptonMaterial;
      const payloads = payload.filter((n) => n.index !== 'NaN');

      /** 如果广告文案为空，仅仅放入广告素材 */
      if (text.length === 0) {
        list = [...list, ...payload].filter((n) => n.index !== 'NaN');
      } else {
        /** 此处区分是否是 smart 且是 sparkads */
        if (state.isSparkAds) {
          list = payloads;
        } else {
          text.forEach((item) => {
            payloads.forEach((n) => {
              list.push({
                ...n,
                adText: item,
                index: ind,
              });
              ind++;
            });
          });
        }
      }

      //** 重排序 */
      if (list.length === 0) {
        list = [
          {
            isEmpty: true,
            isCopy: false,
            isSparkAd: false,
            index: 'NaN',
            itemId: '',
            videoId: '',
            actionGuid: 'optimization',
          },
        ];
      } else {
        list = list.map((item, index) => {
          const { ...temp } = item;
          temp.index = index;
          return temp;
        });
      }
      let newVideoList = Array.from(
        new Set([...state.ACOMaterial, ...payloads]),
      );
      newVideoList = newVideoList.map((item, index) => {
        const { ...temp } = item;
        temp.index = index;
        return temp;
      });
      if (current.index === 'NaN') {
        current = list[0];
      }
      return {
        ...state,
        curOptonMaterial: current,
        ACOMaterial: newVideoList,
        adMaterialList: list,
      };
    },
    setDeleteACOMaterial: (state, { payload }) => {
      /** 素材改变，计算后，加入adMaterialList */
      const text = state.ACOText;
      let list = [];
      let ind = 0;
      let current = state.curOptonMaterial;

      /** 如果广告文案为空，仅仅放入广告素材 */
      if (text.length === 0) {
        list = [];
      } else {
        text.forEach((item) => {
          payload.forEach((n) => {
            list.push({
              ...n,
              adText: item,
              index: ind,
            });
            ind++;
          });
        });
      }

      //** 重排序 */
      if (list.length === 0) {
        list = [
          {
            isEmpty: true,
            isCopy: false,
            isSparkAd: false,
            index: 'NaN',
            itemId: '',
            videoId: '',
            actionGuid: 'optimization',
          },
        ];
      } else {
        list = list.map((item, index) => {
          const { ...temp } = item;
          temp.index = index;
          return temp;
        });
      }
      let newVideoList = payload;
      newVideoList = newVideoList.map((item, index) => {
        const { ...temp } = item;
        temp.index = index;
        return temp;
      });
      if (current.index === 'NaN') {
        current = list[0];
      }
      return {
        ...state,
        curOptonMaterial: current,
        ACOMaterial: newVideoList,
        adMaterialList: list,
      };
    },
    setACOText: (state, { payload }) => {
      /** 文案改变，计算后加入adMaterial */
      const video = state.ACOMaterial;
      let list = [];
      if (video.length === 0) {
        list = state.adMaterialList.filter((n) => n.index !== 'NaN');
      }
      let ind = 0;
      payload.forEach((item) => {
        video.forEach((n) => {
          list.push({
            ...n,
            adText: item,
            index: ind,
          });
          ind++;
        });
      });

      if (list.length === 0) {
        list = [
          {
            isEmpty: true,
            isCopy: false,
            isSparkAd: false,
            index: 'NaN',
            itemId: '',
            videoId: '',
            actionGuid: 'optimization',
          },
        ];
      } else {
        list = list.map((item, index) => {
          item.index = index;
          return item;
        });
      }

      return {
        ...state,
        ACOText: payload,
        // ACOMaterial:list,
        adMaterialList: payload.length ? list : video,
      };
    },
    setCopyACOCommonAttr: (state, { payload }) => {
      return {
        ...state,
        copyACOCommonAttr: payload,
      };
    },
    setCopyACOList: (state, { payload }) => {
      const commonAttr = {
        callToActionId: payload.callToActionId,
        callToActionList: payload.callToActionList,
        creativeAuthorized: payload.creativeAuthorized,
        identityId: payload.identityId,
        identityType: payload.identityType,
        landingPageUrls: payload.landingPageUrls,
        adName: payload.adName,
      };
      return {
        ...state,
        copyACOList: payload,
        copyACOCommonAttr: commonAttr,
      };
    },
    setClearACOMaterial: (state) => {
      return {
        ...state,
        ACOMaterial: [],
        ACOText: [],
        adMaterialList: [
          {
            isEmpty: true,
            isCopy: false,
            isSparkAd: false,
            index: 'NaN',
            itemId: '',
            videoId: '',
            actionGuid: 'optimization',
          },
        ],
      };
    },

    setCopyAdMaterial: (state, { payload }) => {
      const list1 = [];
      const materia = state.adMaterialList;
      materia.forEach((item, index) => {
        list1.push(item);
        if (index === payload) {
          const { ...temp } = item;
          temp.isCopy = true;
          temp.newCopy = true;
          list1.push(temp);
        }
      });

      let current = state.curOptonMaterial;
      const list2 = list1.map((item, ind) => {
        item['index'] = ind;
        if (item.newCopy) {
          delete item.newCopy;
          current = { ...item };
        }
        return item;
      });

      return {
        ...state,
        curOptonMaterial: current,
        adMaterialList: list2,
      };
    },
    setCampaignFormClear: (state, { payload }) => {
      return {
        ...state,
        clearCampaignForm: payload,
      };
    },

    setAdGroupFormClear: (state, { payload }) => {
      return {
        ...state,
        clearAdGroupForm: payload,
      };
    },
    setAdFormClear: (state, { payload }) => {
      return {
        ...state,
        clearAdForm: payload,
      };
    },
    setTimezone: (state, { payload }) => {
      return {
        ...state,
        timezone: payload.displayTimezone,
        ETCTimeZone: payload.timezone,
      };
    },
    setPromotionObjective: (state, { payload }) => {
      return {
        ...state,
        promotionObjective: payload,
      };
    },
    setStoreInfo: (state, { payload }) => {
      return {
        ...state,
        storeInfo: payload,
      };
    },
    setCreativeType: (state, { payload }) => {
      return {
        ...state,
        creativeType: payload,
      };
    },
    setCurrentAdGroup: (state, { payload }) => {
      return {
        ...state,
        currentAdGroup: payload,
      };
    },
  },
};

export default ADMODAL;
