/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-02-20 16:16:57
 * @LastEditors: changfeng
 * @LastEditTime: 2023-07-27 09:30:31
 */
export default {
  'ad.title': 'Advertise',
  'ad.cta.apply now': 'APPLY_NOW',
  'ad.cta.book now': 'BOOK_NOW',
  'ad.cta.contact us': 'CONTACT_US',
  'ad.cta.download now': 'DOWNLOAD_NOW',
  'ad.cta.experience now': 'EXPERIENCE_NOW',
  'ad.cta.get quote': 'GET_QUOTE',
  'ad.cta.get showtimes': 'GET_SHOWTIMES',
  'ad.cta.get tickets now': 'GET_TICKETS_NOW',
  'ad.cta.install now': 'INSTALL_NOW',
  'ad.cta.interest': 'INTERESTED',
  'ad.cta.learn more': 'LEARN_MORE',
  'ad.cta.listen now': 'LISTEN_NOW',
  'ad.cta.order now': 'ORDER_NOW',
  'ad.cta.play games': 'PLAY_GAME',
  'ad.cta.preorder now': 'PREORDER_NOW',
  'ad.cta.read more': 'READ_MORE',
  'ad.cta.shop now': 'SHOP_NOW',
  'ad.cta.sign up': 'SIGN_UP',
  'ad.cta.subscribe': 'SUBSCRIBE',
  'ad.cta.view now': 'VIEW_NOW',
  'ad.cta.view profile': 'VIEW_PROFILE',
  'ad.cta.visit store': 'VISIT_STORE',
  'ad.cta.watch live': 'WATCH_LIVE',
  'ad.cta.watch now': 'WATCH_NOW',
  'ad.cta.join this hashtag': 'JOIN_THIS_HASHTAG',
  'ad.cta.shoot with this effect': 'SHOOT_WITH_THIS_EFFECT',
  'ad.cta.view video with this effect': 'VIEW_VIDEO_WITH_THIS_EFFECT',

  'ad.cta.check it out': 'check it out',
  'ad.cta.buy now': 'buy now',
  'ad.cta.buy it': 'buy it',
  'ad.cta.get it now': 'get it now',
  'ad.cta.order here': 'order here',
  'ad.cta.get yours now': 'get yours now',
  'ad.cta.order yours today': 'order yours today',
  'ad.cta.shop': 'shop',
  'ad.cta.get your order now': 'get your order now',
  'ad.cta.count info':"Up to 3",

  'ad.adName': 'AdName',
  'ad.use material name': 'Use material name',
  'ad.material name': 'Material name',

  'ad.use now time': 'use now time',
  'ad.Identity': 'Identity',
  'ad.spark ad account': ' Use TikTok account to deliver Spark Ads',
  'ad.Identity.choose identity': 'Set custom identity ',
  'ad.Identity.choose identity.all': 'Please add identity for all ad',

  'ad.Identity.new custom': 'Create new custom identity',
  'ad.Identity.authorization': '授权TikTok帖子',
  'ad.Ad details': 'Ad details',
  'ad.Ad format': 'Ad details',
  'ad.video': 'Add video',
  'ad.video.material library': 'From library',
  'ad.video.material.computer': 'Upload',
  'ad.adText': 'Add text',
  'ad.loadingPageUrl': 'Landing page',
  'ad.call to action': 'Call to action',
  'ad.call to action.Dynamic': 'Dynamic',
  'ad.call to action.Dynamic.describe':
    'Automatically show different call to action text to different users to maximize performance.',
  'ad.call to action.Dynamic.view selected': 'View selected text',
  'ad.call to action.Standard': 'Standard',
  'ad.creativeAuthorized.first':
    'By checking this box, you agree for the ad and the associated performance metrics of the campaign to be displayed in the ',
  'ad.creativeAuthorized.last': ' ',
  'ad.call to action.edit text options': 'Selected call to action text',
  'ad.call to action.edit text options.describe':
    'Select the call to action text you want to use in your ad. ',
  'ad.spark.title': 'Post',
  'ad.spark.tiktok.title': 'TikTok post',
  'ad.music.title': 'Music sharing',
  'ad.music.include noauthorization':
    'Unable to obtain song or artist name(s). You cannot currently use this function.',
  'ad.music.allowed user use': 'Allow users to use your music in their videos.',
  'ad.music.authorization':
    'Help increase organic reach and engagement by letting users share your music in their own videos. Copyright authorization for the music is required.',
  'ad.add video material':
    'You can create up to 10 ads under TopView ad group.',
  'ad.call to action.text options': 'Text options',
  'ad.adlist': 'Ad list',

  'ad.message.adtext for every ad': 'Please add AD text to each advertisement',
  'ad.message.creating': 'creating...',
  'ad.message.creating success': 'success',
  'ad.message.choose.identity': 'please choose identity',
  'ad.message.adText.length':
    'The AD copy should be between 1 and 100 half-corner characters (1 and 50 full-corner characters).',
  'ad.message.please choose material': 'please choose material',
  'ad.message.please choose auth Identity':
    'please choose authorization identity',
  'ad.message.please enter adText': 'please enter adText',
  'ad.message.please enter correct url': 'please enter correct landing page',

  'ad.tooltip.sparkAd':
    'Spark Ads are only available in TikTok. You can run your ads through a TikTok account and use posts from TikTok as your ad creatives.',
  'ad.tooltip.sparkad.authorization':
    'You can either authorize individual TikTok posts, or authorize a TikTok account in Business Center and gain access to all of its posts.',
  'ad.tooltip.use other auth': 'Use other authorized account or post',
  'ad.tooltip.adText':
    "Text will appear as part of your ad creative to let your audience know what you're promoting.",

  'ad.button.create': 'create',
  'ad.button.upload': 'upload',
  'ad.button.update': 'update',
  'ad.button.reset': 'reset',
  'ad.button.submit': 'submit',
  'ad.button.add': 'Add',

  'ad.business.center': 'center',

  'ad.upload.title': 'upload',
  'ad.upload.guide': 'Drop files here, or click to upload',
  'ad.upload.click': 'click to upload',
  'ad.upload.format':
    'Recommended format: .mp4, .mov, .mpeg, or .avi File Size: 500 MB',
  'ad.upload.scale':
    'Aspect ratio requirements for all placements: Horizontal(16:9) / Square(1:1) / Vertical(9:16)',
  'ad.upload.resolution ratio':
    'For TikTok: Resolution Horizontal(960*540) / Square(640*640) / Vertical(540*960) / Bitrate ≥ 516.00 Kbpskbps / Duration 5-60s / Safe zone From left: 44px, From right: 140px, From top: 130px, From bottom: 483-484px',
  
  
    
  /** ttStore */
  'ad.material.placeholder': "Please choose material",
  'ad.product.placeholder': "Please add product for all ad",
  'ad.product.source.placeholder':'Please choose product from under source',
  'ad.product.available.source.placeholder': 'Only available products can be selected to advertise',
  'ad.product': 'Producr',
  'ad.product.detail':'Product detail',
};
