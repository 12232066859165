/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-02-20 16:16:57
 * @LastEditors: changfeng
 * @LastEditTime: 2023-07-27 09:30:34
 */
export default {
  'ad.title': '广告',
  'ad.cta.apply now': '立即申请',
  'ad.cta.book now': '立即预约',
  'ad.cta.contact us': '联系我们',
  'ad.cta.download now': '立即下载',
  'ad.cta.experience now': '立即体验',
  'ad.cta.get quote': '查看价格',
  'ad.cta.get showtimes': '查看演出时间',
  'ad.cta.get tickets now': '获取门票',
  'ad.cta.install now': '立即安装',
  'ad.cta.interest': '感兴趣',
  'ad.cta.learn more': '查看详情',
  'ad.cta.listen now': '马上去听',
  'ad.cta.order now': '立即下单',
  'ad.cta.play games': '去玩游戏',
  'ad.cta.preorder now': '立即预定',
  'ad.cta.read more': '阅读更多',
  'ad.cta.shop now': '去逛逛',
  'ad.cta.sign up': '立即注册',
  'ad.cta.subscribe': '订阅',
  'ad.cta.view now': '立即查看',
  'ad.cta.view profile': '浏览主页',
  'ad.cta.visit store': '去附近商店看看',
  'ad.cta.watch live': '观看直播',
  'ad.cta.watch now': '立即观看',
  'ad.cta.join this hashtag': '参加挑战',
  'ad.cta.shoot with this effect': '马上开拍',
  'ad.cta.view video with this effect': '查看更多相关视频',

  'ad.cta.check it out': '立即核销',
  'ad.cta.buy now': '立即购买',
  'ad.cta.buy it': '现在就买',
  'ad.cta.get it now': '立即去取',
  'ad.cta.order here': '在这预定',
  'ad.cta.get yours now': '立即开始',
  'ad.cta.order yours today': '今天预定',
  'ad.cta.shop': '店铺',
  'ad.cta.get your order now': '立即下单',
  'ad.cta.count info':"最多只能选三个",

  'ad.adName': '广告名称',
  'ad.use material name': '使用素材名称',
  'ad.material name': '素材名称',

  'ad.use now time': '使用当前时间',
  'ad.Identity': '广告发布身份',
  'ad.spark ad account': '使用 TikTok 账号投放 Spark Ads',
  'ad.Identity.choose identity': '广告发布身份',
  'ad.Identity.choose identity.all': '请为所有广告添加发布身份',

  'ad.Identity.new custom': '创建自定义身份',
  'ad.Identity.authorization': '授权TikTok帖子',
  'ad.Ad details': '创意详情',
  'ad.Ad format': '创意素材',
  'ad.video': '视频素材',
  'ad.video.material library': '从素材库选择',
  'ad.video.material.computer': '从电脑上传视频',
  'ad.adText': '广告文案',
  'ad.loadingPageUrl': '落地页',
  'ad.call to action': '行动引导文案',
  'ad.call to action.Dynamic': '动态优选',
  'ad.call to action.Dynamic.describe':
    '自动向不同的用户显示不同的行动号召，以提高转化效率。',
  'ad.call to action.Dynamic.view selected': '查看已选行动号召',
  'ad.call to action.Standard': '标准',
  'ad.creativeAuthorized.first': '选中此框，您将允许在',
  'ad.creativeAuthorized.last': '中显示广告以及推广系列的相关效果指标',
  'ad.call to action.edit text options': '编辑候选集',
  'ad.call to action.edit text options.describe':
    '系统会使用您选择的文案进行优选投放以达到最优的投放效果 ',
  'ad.spark.title': '帖子',
  'ad.spark.tiktok.title': 'TikTok帖子',
  'ad.music.title': '推广音乐',
  'ad.music.include noauthorization': '您的帖子中包含未授权的音乐',
  'ad.music.allowed user use': '允许用户在视频中使用您的音乐',
  'ad.music.authorization':
    '让用户在自己的视频中分享您的音乐，帮助增加自然流量覆盖人数和互动率。音乐需要版权授权。',
  'ad.add video material': '添加视频(最多添加30条视频素材)',
  'ad.call to action.text options': '候选文案',
  'ad.adlist': '广告列表',

  'ad.message.adtext for every ad': '请给每条广告配上广告文案',
  'ad.message.creating': '广告创建中...',
  'ad.message.creating success': 'success',
  'ad.message.choose.identity': '请选择广告发布身份',
  'ad.message.adText.length':
    '广告文案应在1～100个半角字符之间（全角字符1～50个）',
  'ad.message.please choose material': '请先选择广告素材',
  'ad.message.please choose auth Identity': '请先选择认证身份',
  'ad.message.please enter adText': '请输入广告文案',
  'ad.message.please enter correct url': '请输入正确的落地页地址',

  'ad.tooltip.sparkAd':
    'Spark Ads 仅在 TikTok 上可用。 你可以通过 TikTok 账户投放广告并将 TikTok 帖子用作广告创意。这可以帮助你塑造账户影响力，增加广告互动，获得更好的长期投放效果。',
  'ad.tooltip.sparkad.authorization':
    '您可以授权单个 TikTok 帖子，也可以在商务中心授权某个 TikTok 账号获取该账号所有帖子的访问权限。',
  'ad.tooltip.use other auth': '使用其他授权账号或帖子',
  'ad.tooltip.adText':
    '文案将作为广告创意素材的一部分向受众展示，向他们传递你要推广的信息',

  'ad.button.create': '创建',
  'ad.button.upload': '上传',
  'ad.button.update': '更新',
  'ad.button.reset': '重置',
  'ad.button.submit': '提交',
  'ad.button.add': '添加',

  'ad.business.center': '创意中心',

  'ad.upload.title': '上传视频',
  'ad.upload.guide': '拖拽文件到此处，或',
  'ad.upload.click': '点击上传',
  'ad.upload.format': '推荐格式: .mp4、.mov、.mpeg 或 .avi 文件大小: 500 MB',
  'ad.upload.scale':
    '所有广告位视频比例要求: 横版(16:9) / 正方形(1:1) / 竖版(9:16)',
  'ad.upload.resolution ratio':
    '关于 TikTok 分辨率 横版(960*540) / 正方形(640*640) / 竖版(540*960) / 码率 ≥ 516.00 Kbpskbps / 时长 5-60s / 安全区域 左侧: 44px, 右侧: 140px, 顶部: 130px, 底部: 483-484px',
  
  
  
  
  /** ttStore */
  'ad.material.placeholder': "请选择素材",
  'ad.product.placeholder': "请为所有广告添加商品信息",
  'ad.product.source.placeholder':'请从以下来源选择商品',
  'ad.product.available.source.placeholder': '仅可选择可用商品来投放广告',
  'ad.product': '商品',
  'ad.product.detail':'商品详情',
  

};
