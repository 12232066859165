/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-02-22 10:31:52
 * @LastEditors: changfeng
 * @LastEditTime: 2023-08-11 10:05:15
 */
export default {
  'adGroup.title': 'Ad group',
  'adGroup.all': 'All',
  'adGroup.new adgroup': 'New',
  'adGroup.choose adgroup': 'Use existing',
  'adGroup.use template': 'Use template',
  'adGroup.new adgroup.edit': 'Edit adgroup',

  'adGroup.website.title': 'Website',
  'adGroup.website.adgroup name': 'Ad group name',
  'adGroup.website.optimization': 'Optimization event',
  'adGroup.website.adgroup name.edit': 'Edit ad group name',

  'adGroup.website.adgroup name.look and edit': 'Look and edit ad group name',

  'adGroup.placement.title': 'Placements',
  'adGroup.placement type automatic':
    'Automatically show your ads across supported placements. ',
  'adGroup.placement type normal': 'Manually choose your targeting placement. ',
  'adGroup.placement.automatic': 'Automatic placement',
  'adGroup.placement.edit': 'Select placement',
  'adGroup.placement.user comment': 'User comment',
  'adGroup.placement.download video': 'Video download',

  'adGroup.aco.title': 'Smart Creative ads',
  'adGroup.aco.describe':
    'Create ads with Smart Creative by auto-generating videos that are diversified across ad creatives, asset smart selection and refresh, to ensure your ad groups are continuously optimized for performance.',
  'adGroup.targeting.title': 'Advertising objective',
  'adGroup.targeting.automatic': 'Automatic targeting',
  'adGroup.targeting.custom': 'Custom targeting',

  'adGroup.Demographics.title': 'Demographics',
  'adGroup.Demographics.region': 'Location',
  'adGroup.Demographics.language': 'Languages',
  'adGroup.Demographics.gender': 'Gender',
  'adGroup.Demographics.gender.male': 'Male',
  'adGroup.Demographics.gender.female': 'Female',
  'adGroup.Demographics.age': 'Age',
  'adGroup.Demographics.age.unit': 'old',
  'adGroup.Demographics.audience': 'Audience',
  'adGroup.Demographics.audience.include': 'Incllude',
  'adGroup.Demographics.audience.exclude': 'Exclude',

  'adGroup.interest.title': 'Interest & Behaviours',
  'adGroup.interest.add interest': 'Add interests',
  'adGroup.interest.add.label':
    ' Target users based on their general interests or purchase intentions. ',
  'adGroup.interest.videoaction.title': 'Add behavior: Video interactions',
  'adGroup.interest.videoaction.type':
    "Target users based on how they've interacted with videos on TikTok. ",
  'adGroup.interest.videoaction.watch end': 'Watched till end',
  'adGroup.interest.videoaction.like click': 'Liked',
  'adGroup.interest.videoaction.comment': 'commented',
  'adGroup.interest.videoaction.share': 'shared',
  'adGroup.interest.videoaction.which video':
    "Define what kind of videos they've interacted with",
  'adGroup.interest.videoaction.duration':
    'Select a time period to include actions from',
  'adGroup.interest.videoaction.duration.days': 'days',

  'adGroup.interest.creatoraction.title': 'Add behavior: Creator interactions',
  'adGroup.interest.creatoraction.type':
    "Define how they've interacted with creators",
  'adGroup.interest.creatoraction.followed': 'Followed',
  'adGroup.interest.creatoraction.viewed profile': 'Viewed profile',
  'adGroup.interest.creatoraction.which creator':
    "Define what kind of creators they've interacted with",
  'adGroup.device.title': 'Device',
  'adGroup.device.operating system': 'Operating system',
  'adGroup.device.OS version': 'OS versions',
  'adGroup.device.model': 'Device model',
  'adGroup.device.connection': 'Connection type',
  'adGroup.device.carriers': 'Carriers',
  'adGroup.device.price': 'Device price',
  'adGroup.device.price.any': ' Any price ',
  'adGroup.device.specific': ' Specific range ',
  'adGroup.expansion': 'Targeting expansion',
  'adGroup.expansion.describe':
    "Targeting expansion is only available for settings which you've selected at least one targeting criteria for.",
  'adGroup.expansion.audience': 'Audience',
  'adGroup.expansion.gender': 'Gender',
  'adGroup.expansion.age': 'Age',
  'adGroup.expansion.interest': ' Interests & Behaviours ',
  'adGroup.schedule&budget': 'Budget & Schedule ',
  'adGroup.budget.daily': 'Daily budget ',
  'adGroup.budget.lifetime': 'Lifetime budget',
  'adGroup.budget.nolimited': 'No limited',
  'adGroup.schedule.title': 'Schedule',
  'adGroup.schedule.startTime':
    ' Run ad group continuously after the scheduled start time ',
  'adGroup.schedule.rangeTime': ' Run ad group within a date range ',
  'adGroup.schedule.rangetime.label': 'DateTime',
  'adGroup.schedule.dayParting': 'Dayparting',
  'adGroup.schedule.dayParting.all': 'All day',
  'adGroup.schedule.dayParting.specific': ' Select specific time ',

  'adGroup.Optimization.title': 'Bidding & Optimization',
  'adGroup.Optimization.goal': 'Optimization goal',
  'adGroup.Optimization.goal.conversion': 'Conversion',
  'adGroup.Optimization.goal.click': 'Click',
  'adGroup.Optimization.Bid strategy': 'Bid strategy',
  'adGroup.Optimization.cost cap': 'Cost cap',
  'adGroup.Optimization.lowest cost': 'lowest cost',

  'adGroup.billing event': 'Billing event',
  'adGroup.billevent.impression': 'Impression',
  'adGroup.delivery type': 'Delivery type',
  'adGroup.delivery type.standard': 'Standard',
  'adGroup.delivery type.speedup': 'Speed up',

  'adGroup.message.no adGroup': 'No AD groups under the current AD account',
  'adGroup.message.exit aco':
    'Smart Creative ads already exist under the current AD account',
  'adGroup.message.creating': 'creating...',
  'adGroup.message.create adgroup success': 'success',
  'adGroup.message.template name': 'Please enter template name',
  'adGroup.message.template success': 'success',
  'adGroup.message.please enter adGroup name': 'please enter adGroup name ',
  'adGroup.message.please choose pixel': 'please choose pixel',
  'adGroup.message.please choose placement': 'please choose placement',
  'adGroup.message.Demographics.region': 'please choose audience',
  'adGroup.message.action type': 'please choose action',
  'adGroup.message.action.category': 'search or select categories',
  'adGroup.message.action.duration': 'Run ad group within a date range',
  'adGroup.message.dateTimeRange':
    'Run ad group continuously after the scheduled start time',
  'adGroup.message.optimization goal':
    'The event’s attribution settings are based on your settings in Attribution Manager',
  'adGroup.message.bid type custom':
    'Please set the budget of the advertising team first',
  'adGroup.message.conversionPrice': 'Please set the bid first',
  'adGroup.message.conversionLimited':
    'Bids cannot exceed the AD group budget value',
  // 'adGroup.message.':'',
  // 'adGroup.message.':'',
  // 'adGroup.message.':'',

  'adGroup.footer.button.template': 'Save',
  'adGroup.footer.button.copy': 'Copy',

  'adGroup.tooltip.placement type automatic':
    'Automatically show your ads across supported placements.',
  'adGroup.tooltip.placement type normal':
    'Manually choose your targeting placement. ',
  'adGroup.tooltip.targeting.automatic':
    'Optimize targeting based on your objective, creative, and performance. Ads will not be delivered to audiences under 18.',
  'adGroup.tooltip.targeting.custom':
    'Manually set criteria for what kind of user you want to show your ads to.',
  'adGroup.tooltip.pay for ad': 'Determines when you pay for your ad',
  'adGroup.tooltip.delivery type':
    'Determines how your budget and ads are spent and distributed within 24 hours',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',
  // 'adGroup.tooltip.':'',

  'adGroup.content.max value': 'Maximize your number of results.',
  'adGroup.content.max value.describe':
    'Keep your average cost per result lower than your bid. ',
  'adGroup.content.pacing':
    'Your budget and ads are distributed evenly based on the business traffic during the selected advertising period. If the traffic is heavy, your ads are sent more frequently, and vice versa.',

  'adGroup.formLabel.lowLimitedValue':
    'Set a lower bound on Return on AD spend (ROAS)',
  'adGroup.formLabel.choose.adGroup': 'Choose a adGroup',

  'adGroup.template.headTitle': 'Template list',
  'adGroup.template.use template': 'Use it',

  /** schedule日期表格 */

  'adGroup.schedule.week': 'week',
  'adGroup.schedule.time': 'time',
  'adGroup.schedule.week.Monday': 'Mon',
  'adGroup.schedule.week.Tuesday': 'Tue',
  'adGroup.schedule.week.Wednesday': 'Wed',
  'adGroup.schedule.week.Thursday': 'Thu',
  'adGroup.schedule.week.Friday': 'Fri',
  'adGroup.schedule.week.Saturday': 'Sat',
  'adGroup.schedule.week.Sunday': 'Sun',
  'adGroup.schedule.drag to choice': 'Drag the mouse to select the time period',
  'adGroup.schedule.clear': 'Clear All',

  /** pixel优化事件 */
  'adgroup.pixelevent.SHOPPING': 'Complete Payment',
  'adgroup.pixelevent.ADD_BILLING': 'Add Payment Info',
  'adgroup.pixelevent.INITIATE_ORDER': 'Initiate Checkout',
  'adgroup.pixelevent.ON_WEB_CART': 'Add to Cart',
  'adgroup.pixelevent.ON_WEB_DETAIL': 'View Content',
  'adgroup.pixelevent.ON_WEB_SEARCH': 'Search',
  'adgroup.pixelevent.BUTTON': 'Click Button',
  'adgroup.pixelevent.ON_WEB_ADD_TO_WISHLIST': 'Add to Wishlist',
  'adgroup.pixelevent.ON_WEN_ORDER': 'Order',
  'adgroup.pixelevent.CONSULT': 'Consult',
  'adgroup.pixelevent.DOWNLOAD_START': 'Download',
  'adgroup.pixelevent.FORM': 'Submit Form',
  'adgroup.pixelevent.ON_WEB_REGISTER': 'Register',
  'adgroup.pixelevent.ON_WEB_SUBSCRIBE': 'Subscribe',

  'adgroup.timezone':'Ads are served based on the time zone of your registered account'

};
