/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-11-30 10:57:30
 * @LastEditors: changfeng
 * @LastEditTime: 2023-03-30 10:52:31
 */
export default {
  'pages.layouts.userLayout.title':
    'Ant Design 是西湖区最具影响力的 Web 设计规范',
  'pages.login.accountLogin.tab': '账户密码登录',
  'pages.login.accountLogin.errorMessage':
    '错误的用户名和密码(admin/ant.design)',
  'pages.login.failure': '登录失败，请重试',
  'pages.login.success': '登录成功',
  'pages.login.username.placeholder': '用户名: admin or user',
  'pages.login.username.required': '用户名是必填项',
  'pages.login.password.label': '密码',
  'pages.login.password.placeholder': '请输入密码',
  'pages.login.password.required': '密码是必填项',
  'pages.login.verification.code': '验证码',
  'pages.login.phoneLogin.tab': '手机号登录',
  'pages.login.phoneLogin.errorMessage': '验证码错误',
  'pages.login.phoneNumber.placeholder': '请输入手机号',
  'pages.login.phoneNumber.required': '手机号是必填项',
  'pages.login.phoneNumber.invalid': '不合法的手机号',
  'pages.login.captcha.placeholder': '请输入验证码',
  'pages.login.captcha.required': '验证码是必填项',
  'pages.login.phoneLogin.getVerificationCode': '获取验证码',
  'pages.getCaptchaSecondText': '秒后重新获取',
  'pages.login.rememberMe': '保持登录状态',
  'pages.login.forgotPassword': '忘记密码?',
  'pages.login.submit': '登录',
  'pages.login.loginWith': '其他登录方式:',
  'pages.login.registerAccount': '注册账户',
  'pages.login.createAccount': '创建 NewRate 账户',
  'pages.login.company': '公司',
  'pages.login.company.placeholder': '请输入公司名称',
  'pages.login.company.errorMessage': '公司名称必填',
  'pages.login.haveAccount': '已有账户?',
  'pages.login.retrieve.password': '找回密码',
  'pages.login.backToLogin': '返回登录',
  'pages.login.reset': '提交',
  'pages.welcome.link': '欢迎使用',
  'pages.welcome.alertMessage': '更快更强的重型组件，已经发布。',
  'pages.admin.subPage.title': ' 这个页面只有 admin 权限才能查看',
  'pages.admin.subPage.alertMessage':
    'umi ui 现已发布，欢迎使用 npm run ui 启动体验。',
  'pages.searchTable.createForm.newRule': '新建规则',
  'pages.searchTable.updateForm.ruleConfig': '规则配置',
  'pages.searchTable.updateForm.basicConfig': '基本信息',
  'pages.searchTable.updateForm.ruleName.nameLabel': '规则名称',
  'pages.searchTable.updateForm.ruleName.nameRules': '请输入规则名称！',
  'pages.searchTable.updateForm.ruleDesc.descLabel': '规则描述',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': '请输入至少五个字符',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    '请输入至少五个字符的规则描述！',
  'pages.searchTable.updateForm.ruleProps.title': '配置规则属性',
  'pages.searchTable.updateForm.object': '监控对象',
  'pages.searchTable.updateForm.ruleProps.templateLabel': '规则模板',
  'pages.searchTable.updateForm.ruleProps.typeLabel': '规则类型',
  'pages.searchTable.updateForm.schedulingPeriod.title': '设定调度周期',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': '开始时间',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': '请选择开始时间！',
  'pages.searchTable.titleDesc': '描述',
  'pages.searchTable.ruleName': '规则名称为必填项',
  'pages.searchTable.titleCallNo': '服务调用次数',
  'pages.searchTable.titleStatus': '状态',
  'pages.searchTable.nameStatus.default': '关闭',
  'pages.searchTable.nameStatus.running': '运行中',
  'pages.searchTable.nameStatus.online': '已上线',
  'pages.searchTable.nameStatus.abnormal': '异常',
  'pages.searchTable.titleUpdatedAt': '上次调度时间',
  'pages.searchTable.exception': '请输入异常原因！',
  'pages.searchTable.titleOption': '操作',
  'pages.searchTable.config': '配置',
  'pages.searchTable.subscribeAlert': '订阅警报',
  'pages.searchTable.title': '查询表格',
  'pages.searchTable.new': '新建',
  'pages.searchTable.chosen': '已选择',
  'pages.searchTable.item': '项',
  'pages.searchTable.totalServiceCalls': '服务调用次数总计',
  'pages.searchTable.tenThousand': '万',
  'pages.searchTable.batchDeletion': '批量删除',
  'pages.searchTable.batchApproval': '批量审批',

  /** 页面信息 */
  'pages.pageTitle.AccountAuthorization': '账号授权',
  'pages.pageTitle.StrategyList': '策略列表',
  'pages.pageTitle.TemplateList': '模板列表',
};
