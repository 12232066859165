/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-02-22 11:41:27
 * @LastEditors: changfeng
 * @LastEditTime: 2023-08-31 17:24:32
 */
export default {
  'campaign.title': 'campaign',
  'campaign.create.new': 'new campaign ',
  'campaign.select': 'Use existing',
  'campaign.select.exiting': 'Choose a campaign',
  'campaign.name': 'Campaign name',
  'campaign.set campaign budget': 'budget',
  'campaign.set campaign budget on': 'Campaign budget optimization',
  'campaign.name.edit': 'edit campaign name',


  'campaign.adgroup count.prefix': 'The campaign has',
  'campaign.adgroup count.after': 'ad groups',
  

  'campaign.target.webconversion': "Awareness",
  'campaign.target.productsale': "Product sales",
  'campaign.target.productsale.describe': "Sell products from your TikTok Shop, website, and app.",

  'campaign.advertising.objective':'Advertising objective',
  'campaign.advertising.objective.website': 'Website conversions',
  'campaign.advertising.objective.website.describe': 'Drive valuable actions on your website.',
  
  'campaign.advertising.objective.Product sales':'Product sales',
  'campaign.advertising.objective.placeholder':'Please choose advertising objective',


  'campaign.Product source': 'Product source',
  'campaign.Product source detail':'Product source',
  
  'campaign.Product source.placeholder':'Please choose product source',
  'campaign.Product source.catalog':'Catalog',
  'campaign.Product source.store':'TikTok Shop',

  'campaign.create success': 'Successfully created',
  'campaign.budget.day': 'Daily budget',
  'campaign.budget.total': 'General budget',
  'campaign.budget.placeholder': 'above 50.00',

  'campaign.form.message.adAccount': 'please choose account',
  'campaign.form.message.please choose campaign': 'please choose campaign',
  'campaign.form.message.please input campaignName': 'please enter campaign name',

  'campaign.form.message.budget': 'Please enter a number not less than 50',

  'campaign.name.look and edit': "View and modify promotional series names",

  'campaign.schedule.look and edit':"View and modify promotional adGroup start time"


};
