/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-01-03 11:10:51
 * @LastEditors: changfeng
 * @LastEditTime: 2023-06-25 17:23:33
 */

import { Reducer } from '@umijs/max';

type State = {
  dateTime: string;
  adAccount: string;
  adGroupId: string;
  adAccountInfo: any
};

type AdModal = {
  namespace: string;
  state: State;
  reducers: ReducerType;
};

type ReducerType = {
  setDateTime: Reducer<State>;
  setAdAccount: Reducer<State>;
  setAdGroup: Reducer<State>;
  setAdAccountInfo: Reducer<State>;

};
const STATISTIC: AdModal = {
  namespace: 'STATISTIC',
  state: {
    dateTime: '',
    adAccount: '',
    adGroupId: '',
    adAccountInfo: {
      
    }
  },

  reducers: {
    setDateTime: (state, { payload }) => {
      return {
        ...state,
        dateTime: payload,
      };
    },
    setAdAccount: (state, { payload }) => {

      return {
        ...state,
        adAccount: payload,
      };
    },
    setAdGroup: (state, { payload }) => {
      return {
        ...state,
        adGroupId: payload,
      };
    },
    setAdAccountInfo: (state, { payload }) => {
      console.log('payload', payload)
      console.log('sttttttttt',state)
      return {
        ...state,
        adAccountInfo: {
          ...payload
        }
      }
    }
  },
};

export default STATISTIC;
