/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-11-30 10:57:30
 * @LastEditors: changfeng
 * @LastEditTime: 2023-07-26 16:12:31
 */
import component from './en-US/component';
import menu from './en-US/menu';
import pages from './en-US/pages';
import pwa from './en-US/pwa';
import settingDrawer from './en-US/settingDrawer';
import settings from './en-US/settings';
import table from './en-US/table';
import strategy from './en-US/strategy';
import awsome from './en-US/awsome';
import user from './en-US/user';
import message from './en-US/message';
import home from './en-US/home';
import privacy from './en-US/privacy';
import ad from './en-US/ad';
import campaign from './en-US/campaign';
import common from './en-US/common';
import advertise from './en-US/advertise';
import adGroup from './en-US/adGroup';
import copy from './en-US/copy';

export default {
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.copyright.produced': 'Produced by Ant Financial Experience Department',
  'app.preview.down.block': 'Download this page to your local project',
  'app.welcome.link.fetch-blocks': 'Get all block',
  'app.welcome.link.block-list':
    'Quickly build standard, pages based on `block` development',
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...pages,
  ...table,
  ...strategy,
  ...awsome,
  ...user,
  ...message,
  ...home,
  ...privacy,
  ...ad,
  ...adGroup,
  ...advertise,
  ...campaign,
  ...common,
  ...copy
};
