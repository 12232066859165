/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-02-13 14:00:52
 * @LastEditors: changfeng
 * @LastEditTime: 2023-02-13 14:27:53
 */
export default {
  'privacy.title': '网页隐私政策',
  'privacy.description':
    'NewRate Tool尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，NewRate Tool会按照本隐私权政策的规定使用和披露您的个人信息。但NewRate Tool将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，NewRate Tool不会将这些信息对外披露或向第三方提供。NewRate Tool会不时更新本隐私权政策。 您在同意NewRate Tool服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于NewRate Tool服务使用协议不可分割的一部分。',
  'privacy.one.title': '1. 适用范围',
  'privacy.one.list1':
    '  1. 在您注册NewRate Tool帐号时，您根据NewRate Tool要求提供的个人注册信息。',
  'privacy.one.list2':
    '  2. 在您使用NewRate Tool网络服务，或访问NewRate Tool平台网页时，NewRate Tool自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据。',
  'privacy.one.list3':
    '  3. NewRate Tool通过合法途径从商业伙伴处取得的用户个人数据。 您了解并同意，以下信息不适用本隐私权政策：',
  'privacy.one.list3.tip1':
    '1. 您在使用NewRate Tool平台提供的搜索服务时输入的关键字信息。',
  'privacy.one.list3.tip2':
    '2. 违反法律规定或违反NewRate Tool规则行为及NewRate Tool已对您采取的措施。',

  'privacy.two.title': '2. 信息使用',
  'privacy.two.list1':
    '  1. NewRate Tool不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和NewRate Tool（含NewRate Tool关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。',
  'privacy.two.list2':
    '  2. NewRate Tool亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何NewRate Tool平台用户如从事上述活动，一经发现，NewRate Tool有权立即终止与该用户的服务协议。',
  'privacy.two.list3':
    '  3. 为服务用户的目的，NewRate Tool可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与NewRate Tool合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。',

  'privacy.three.title': '3. 信息披露',
  'privacy.three.subtitle':
    '在如下情况下，NewRate Tool将依据您的个人意愿或法律规定全部或部分地披露您的个人信息：',
  'privacy.three.list1': '  1. 经您事先同意，向第三方披露。',
  'privacy.three.list2':
    '  2. 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息。',
  'privacy.three.list3':
    '  3. 根据法律的有关规定，或者行政、司法机构的要求，向第三方或者行政、司法机构披露。',
  'privacy.three.list4':
    '  4. 如您出现违反中国有关法律、法规或者NewRate Tool服务协议或相关规则的情况，需要向第三方披露。',
  'privacy.three.list5':
    '  5. 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷。',
  'privacy.three.list6':
    '  6. 在NewRate Tool平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，NewRate Tool有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。',
  'privacy.three.list7': '  7. 其它根据法律、法规或者网站政策认为合适的披露。',

  'privacy.four.title': '4. 信息存储和交换',
  'privacy.four.subtitle':
    'NewRate Tool收集的有关您的信息和资料将保存在NewRate Tool及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或NewRate Tool收集信息和资料所在地的境外并在境外被访问、存储和展示。',

  'privacy.five.title': '5. Cookie的使用',
  'privacy.five.list1':
    '  1. 在您未拒绝接受cookies的情况下，NewRate Tool会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的NewRate Tool平台服务或功能。NewRate Tool使用cookies可为您提供更加周到的个性化服务，包括推广服务。',
  'privacy.five.list2':
    '  2. 您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的NewRate Tool网络服务或功能。',
  'privacy.five.list3':
    '  3. 通过NewRate Tool所设cookies所取得的有关信息，将适用本政策。',

  'privacy.six.title': '6. 信息安全',
  'privacy.six.list1':
    '  1. NewRate Tool帐号均有安全保护功能，请妥善保管您的用户名及密码信息。NewRate Tool将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。',
  'privacy.six.list2':
    '  2. 在使用NewRate Tool网络服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是NewRate Tool用户名及密码发生泄露，请您立即联络NewRate Tool客服，以便NewRate Tool采取相应措施。',
};
