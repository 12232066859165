/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-12-05 17:52:11
 * @LastEditors: changfeng
 * @LastEditTime: 2023-07-18 14:34:44
 */
export default {
  'strategy.name': 'Strategy name',
  'strategy.status': 'Strategy status',
  'strategy.type': 'Strategy type',

  'strategy.too.much': 'You can create up to 20 policies',

  'strategy.name.placeholder': 'Please enter the strategy name',
  'strategy.effective_time': 'Effctive time',
  'strategy.effective_time.tooltip':
    'The policy takes effect only within the specified period',

  'strategy.effective_account': 'please select an advertise account',
  'strategy.latitude.title': 'Scope',
  'strategy.latitude.advertiser': 'Advertiser',
  'strategy.latitude.campaign': 'Campaign',
  'strategy.latitude.adgroup': 'Adgroup',
  'strategy.latitude.ad': 'Ad',
  'strategy.latitude.errorMessage': 'The effective latitude is required',
  'strategy.latitude.scopeId': 'Scope ID',

  'strategy.scope.title': 'Effective scope',
  'strategy.scope.STATUS_DELIVERY_OK': 'STATUS_DELIVERY_OK',
  'strategy.scope.STATUS_DISABLE': 'STATUS_DISABLE',
  'strategy.scope.STATUS_NOT_DELIVERY': 'STATUS_NOT_DELIVERY',
  'strategy.scope.STATUS_NOT_DELETE': 'STATUS_NOT_DELETE',
  'strategy.scope.errorMessage': 'The effective range is required',

  'strategy.repeat.title': 'Repeated effect',
  'strategy.repeat.always': 'Repeated',
  'strategy.repeat.once': 'Once a day',
  'strategy.repeat.errorMessage': 'Please select weather to repeat',

  'strategy.reportPeriod.title': 'Report time Range',
  'strategy.reportPeriod.today': 'Today',
  'strategy.reportPeriod.last two days': 'Last 2 days',
  'strategy.reportPeriod.last three days': 'Last 3 days',
  'strategy.reportPeriod.last seven days': 'Last 7 days',
  'strategy.reportPeriod.last two weeks': 'Last 2 weeks',
  'strategy.reportPeriod.Advertising period': 'Lifetime',
  'strategy.reportPeriod.errorMessage': 'Please select the report validity dimension',



  'strategy.rule_condition': 'Strategy condition',
  'strategy.action': 'Action',
  'strategy.action.params': 'Action params',

  'strategy.frequency.title': 'Execution frequency',
  'strategy.frequency.placeholder': 'Please Select Execution frequency',
  'strategy.frequency.mins': 'mins',
  'strategy.frequency.hour': 'hour',
  'strategy.frequency.hours': 'hours',

  'strategy.log.startTime': 'startTime',
  'strategy.log.endTime': 'endTime',
};
