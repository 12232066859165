/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2022-12-07 10:17:18
 * @LastEditors: changfeng
 * @LastEditTime: 2023-07-26 18:19:33
 */
export default {
  'message.title': '提示',
  'message.share.tips': '分享链接已复制到剪切板',

  'message.delete': '确定删除？',
  'message.delete.strategy': '确定删除策略？',

  'message.success': '操作成功!',
  'message.success.addStrategy': '新建策略成功!',
  'message.success.add': '添加成功',
  'message.success.fixed': '修改成功',
  'message.timeRange.info': '结束时间必须大于开始时间',

  /** service error message */
  此用户不存在: '此用户不存在',
  邀请用户不存在: '邀请用户不存在',
  用户信息错误: '用户信息错误',
  '策略不存在,请仔细检查参数': '策略不存在,请仔细检查参数',
  '广告账户不存在，请检查广告账户信息': '广告账户不存在，请检查广告账户信息',
  '告策略已经删除，请勿重复操作': '告策略已经删除，请勿重复操作',
  '邮件格式错误,请检查格式': '邮件格式错误,请检查格式',
  'tiktok 登录地址出现错误,请检查tiktok的登录信息是否正确':
    'tiktok 登录地址出现错误,请检查tiktok的登录信息是否正确',
  策略修改成功: '策略修改成功',
  ok: '成功',
  fail: '失败',
  loading: '加载中',
  'message.information.atleastone': '请至少选择一条数据',
  广告策略已绑定或广告策略为空: '广告策略已绑定或广告策略为空',
  'tiktok信息为进行绑定,请绑定Tiktok信息':
    'tiktok信息为进行绑定,请绑定Tiktok信息',

  /** 广告创建的message */
  'message.identity.displayName': '请输入自定义名称',
  'message.identity.creating': '自定义身份创建中',
  'message.adParams.check.landingPage.pre': '请为第',
  'message.adParams.check.landingPage.last': '个广告添加landingPageUrl',

  'message.adParams.check.identity.pre': '请为第',
  'message.adParams.check.identity.last': '个广告添加发布身份',

  'message.adParams.check.adText.pre': '请为第',
  'message.adParams.check.adText.last': '个广告添加广告文案',

  'message.back':'返回'
};
