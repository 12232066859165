/*
 * @Description:
 * @Version: 1.0
 * @Author: changfeng
 * @Date: 2023-04-03 10:29:04
 * @LastEditors: changfeng
 * @LastEditTime: 2023-04-03 14:09:20
 */
import { Result } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const Loading = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
      }}
    >
      <Result icon={<LoadingOutlined />} title="加载中" />
    </div>
  );
};

export default Loading;
